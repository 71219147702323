import { z } from "zod";
import { BackendSchema, FrontendSchema, ZObjectId } from "./lib";
import { ZTipTapRichText } from "./TextItem";

// This type is used as the required parsed shape of a figma text node in the figma plugin. Additional fields that don't need to be parsed from the figma plugin can be added to the `ZFigmaTextNode` type to be used in the backend.
export const ZFigmaTextNodeSerialized = z.object({
  nodeId: z.string(),
  pageId: z.string(),
  name: z.string(),
  type: z.literal("TEXT"),
  parentNodePath: z.string(),
  text: z.string(),
  richText: ZTipTapRichText,
  position: z.object({
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
  }),
});

export type IFigmaTextNodeSerialized = z.infer<typeof ZFigmaTextNodeSerialized>;

export const ZFigmaTextNode = ZFigmaTextNodeSerialized.extend({
  _id: ZObjectId,
  fileId: z.string(),
  branchId: z.string().nullable(),
  workspaceId: ZObjectId,
  createdAt: z.date(),
  updatedAt: z.date(),
});

type IFigmaTextNode = z.infer<typeof ZFigmaTextNode>;
export type IBFigmaTextNode = BackendSchema<IFigmaTextNode>;
export type IFFigmaTextNode = FrontendSchema<IFigmaTextNode>;
