import { getTextItemActivity } from "@/http/comp";
import { IFActualChange } from "@shared/types/ActualChange";
import { ICommentThread } from "@shared/types/CommentThread";
import { atom } from "jotai";
import { atomWithRefresh } from "jotai/utils";
import { selectedTextItemIdsAtom } from "./ProjectSelection";
import { textItemChangedFamilyAtom } from "./websocketAtom";

export const selectedTextItemActivityAtom = atomWithRefresh(async (get) => {
  const selectedTextItemIds = get(selectedTextItemIdsAtom);

  if (selectedTextItemIds.length !== 1) {
    return [];
  }

  get(textItemChangedFamilyAtom(selectedTextItemIds[0]));

  const [request] = getTextItemActivity({ textItemId: selectedTextItemIds[0] });
  const result = await request;

  return result.data;
});

const isCommentThread = (activity: IFActualChange | ICommentThread): activity is ICommentThread => {
  return "comments" in activity;
};

export const unresolvedCommentThreadsAtom = atom(async (get) => {
  const selectedTextItemActivity = await get(selectedTextItemActivityAtom);

  if (!selectedTextItemActivity) {
    return [];
  }

  // `selectedTextItemActivity` calls the `historyAndComments` endpoint, so we have to filter out the
  // change items and only return the comment threads that are not resolved.
  return selectedTextItemActivity.filter(isCommentThread).filter((activity) => !activity.is_resolved);
});
