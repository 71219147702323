import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

export type Status = "NONE" | "WIP" | "REVIEW" | "FINAL";

interface IOverlappingBubblesProps {
  className?: string;
  style?: React.CSSProperties;

  statuses: Status[];
  size?: "default" | "sm";
  noneStatusClassName?: string;
}

const SIZE_MARGINS = {
  sm: -0.3,
  default: -2,
};

function OverlappingBubbles(props: IOverlappingBubblesProps) {
  const size = props.size ?? "default";
  return (
    <div className={style.overlappingBubbles}>
      {props.statuses.map((status, index) => (
        <div
          key={index}
          className={classNames(
            style.bubble,
            style[`status-${status}`],
            style.overlap,
            style[`size-${size}`],
            props.noneStatusClassName ?? ""
          )}
          style={{
            zIndex: props.statuses.length - index,
            marginLeft: index === 0 ? 0 : SIZE_MARGINS[size],
          }}
        ></div>
      ))}
    </div>
  );
}

export function Bubble({ status }: { status: Status }) {
  return <div className={classNames(style.bubble, style[`status-${status}`])}></div>;
}

export default OverlappingBubbles;
