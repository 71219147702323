import SeparatorDot from "@ds/atoms/SeparatorDot";
import Text from "@ds/atoms/Text";
import Scrollbar from "@ds/molecules/Scrollbar";
import classNames from "classnames";
import React from "react";
import style from "./style.module.css";

interface DesignPreviewsProps {
  className?: string;
}

const DesignPreviews = (props: DesignPreviewsProps) => {
  return (
    <div className={classNames(style.designPreviewsContainer, props.className)}>
      <Scrollbar className={style.scrollWrapper}>
        <div className={style.previews}>
          <DesignPreview />
          <DesignPreview />
          <DesignPreview />
        </div>
      </Scrollbar>
    </div>
  );
};

function DesignPreview() {
  return (
    <div className={style.preview}>
      <div className={style.header}>
        <Text size="small" color="secondary">
          Frame Name
        </Text>

        <div className={style.details}>
          <Text size="small" color="secondary">
            Fetched 28 minutes ago
          </Text>
          <SeparatorDot />
          <Text size="small" color="secondary">
            Open in Figma
          </Text>
        </div>
      </div>

      <div className={style.image}></div>
    </div>
  );
}

export default DesignPreviews;
