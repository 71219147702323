import classNames from "classnames";
import React from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IInstanceCountProps {
  className?: string;
  count: number;
  onPreviewClick: () => void;
}

export function InstanceCount(props: IInstanceCountProps) {
  if (props.count === 0) return <></>;

  return (
    <div className={classNames(style.InstanceCountWrapper, props.className)}>
      <Text color="secondary" weight="medium" size="small">
        {props.count} instances in design
      </Text>
      <Button level="secondary" onClick={props.onPreviewClick}>
        <Text color="action" size="micro">
          Preview
        </Text>
      </Button>
    </div>
  );
}

export default InstanceCount;
