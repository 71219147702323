import { reorderTextItemsActionAtom } from "@/stores/Editing";
import {
  blockFamilyAtom,
  flattenedProjectItemsAtom,
  INavBlockItem,
  INavMessageItem,
  INavTextItem,
  isValidBlock,
  projectSidebarCollapsedAtom,
  textItemFamilyAtom,
} from "@/stores/Project";
import { designPreviewToggledAtom } from "@/stores/ProjectFiltering";
import {
  clearSelectionActionAtom,
  onClickBlockActionAtom,
  onTextItemClickActionAtom,
  onTextItemKeyDownActionAtom,
  selectedBlockIdAtom,
  selectedTextItemIdsAtom,
  setSelectedBlockIdsActionAtom,
  setSelectedTextIdsActionAtom,
} from "@/stores/ProjectSelection";
import Button from "@ds/atoms/Button";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import Icon from "@ds/atoms/Icon";
import Text from "@ds/atoms/Text";
import NavItem from "@ds/molecules/NavigatorRow";
import Scrollbar from "@ds/molecules/Scrollbar";
import TabGroup from "@ds/molecules/TabGroup";
import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import classNames from "classnames";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Suspense, useMemo } from "react";
import { z } from "zod";
import style from "./style.module.css";

const sidebarTabs = [
  { id: "text", label: "Text", Content: () => <></> },
  { id: "library", label: "Library", Content: () => <></> },
];

function LeftSidebar() {
  const onTextItemKeyDownAction = useSetAtom(onTextItemKeyDownActionAtom);
  const [sidebarCollapseState, setSidebarCollapseState] = useAtom(projectSidebarCollapsedAtom);
  const designPreviewToggled = useAtomValue(designPreviewToggledAtom);

  // TODO: This sidebar should be resizeable by the user, and store that resize in localstorage. DIT-8138
  return (
    <div
      className={classNames(style.leftSidebarContainer, {
        [style.relPos]: !designPreviewToggled,
        [style.absPos]: designPreviewToggled,
      })}
      data-state={sidebarCollapseState}
      tabIndex={0}
      onKeyDown={onTextItemKeyDownAction}
    >
      <div className={style.tabs}>
        <TabGroup tabs={sidebarTabs} />

        <Button variant="icon" className={style.sidebarCollapseBtn} onClick={() => setSidebarCollapseState("closed")}>
          <Icon Icon={<KeyboardDoubleArrowLeft />} />
        </Button>
      </div>
      <Scrollbar disableScrollX className={style.scrollWrapper}>
        <div className={style.contentWrapper}>
          <Suspense fallback={<div>Loading...</div>}>
            <LeftSidebarContent />
          </Suspense>
        </div>
      </Scrollbar>
    </div>
  );
}

function LeftSidebarContent() {
  // TODO: Implement Selection
  // TODO: Implement Reordering
  // TODO: Implement Loading State
  const { flattenedProjectItems } = useAtomValue(flattenedProjectItemsAtom);

  return (
    <>
      {flattenedProjectItems.length ? (
        <>
          {flattenedProjectItems.map((item) => (
            <Suspense key={item._id} fallback={<div>Loading...</div>}>
              {item.type === "block" && <BlockNavItem item={item} />}
              {item.type === "text" && <TextNavItem item={item} />}
              {item.type === "message" && <MessageNavItem item={item} />}
            </Suspense>
          ))}
        </>
      ) : (
        <Text color="tertiary" size="small" className={style.emptyStateMessage}>
          Blocks and text in your project will appear here
        </Text>
      )}
      <HiddenProjectItemsNavItem />
    </>
  );
}

function BlockNavItem(props: { item: INavBlockItem }) {
  const block = useAtomValue(blockFamilyAtom(props.item._id));
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const setSelectedBlockIds = useSetAtom(setSelectedBlockIdsActionAtom);
  const clearSelectionAction = useSetAtom(clearSelectionActionAtom);
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const onClickBlockAction = useSetAtom(onClickBlockActionAtom);

  function handleDrop(textItemIds: string[], dragLocation: "above" | "below" | null) {
    reorderTextItemsAction([
      {
        textItemIds: textItemIds,
        blockId: props.item._id,
      },
    ]);
  }

  if (!isValidBlock(block)) return <></>;

  return (
    <DragAndDroppable
      className={style.draggableNavItem}
      draggableItems={[]}
      allowedItemKeys={{ "ditto/textItem": z.string() }}
      onDrop={handleDrop}
    >
      {(dragAndDropProps) => {
        const isSelected = selectedBlockId === props.item._id;

        return (
          <>
            <NavItem
              key={block._id}
              className={style.navItem}
              type="block"
              pressed={isSelected}
              onChange={(pressed) => {
                if (pressed) onClickBlockAction(block._id!);
              }}
            >
              {block.name || "Untitled Block"}
            </NavItem>
            {dragAndDropProps.isDropTarget && <div className={style.dropIndicator} />}
          </>
        );
      }}
    </DragAndDroppable>
  );
}

function TextNavItem(props: { item: INavTextItem }) {
  const textItem = useAtomValue(textItemFamilyAtom(props.item._id));
  const [selectedTextItemIds] = useAtom(selectedTextItemIdsAtom);
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const onTextItemClick = useSetAtom(onTextItemClickActionAtom);
  const setSelectedTextItemIds = useSetAtom(setSelectedTextIdsActionAtom);
  const clearSelectionAction = useSetAtom(clearSelectionActionAtom);
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);

  const itemType = textItem.blockId ? "block-child" : "default";

  // HANDLE DRAG AND DROP
  const draggableItems = useMemo(
    () =>
      Array.from(new Set([...selectedTextItemIds, props.item._id, props.item._id])).map((item) => ({
        "ditto/textItem": item,
        "plain/text": item,
      })),
    [selectedTextItemIds, props.item._id]
  );

  function handleDrop(textItemIds: string[], dragLocation: "above" | "below" | null) {
    reorderTextItemsAction([
      {
        textItemIds: textItemIds,
        blockId: textItem.blockId,
        before: dragLocation === "above" ? props.item._id : undefined,
        after: dragLocation === "below" ? props.item._id : undefined,
      },
    ]);
  }

  return (
    <DragAndDroppable
      draggableItems={draggableItems}
      allowedItemKeys={{ "ditto/textItem": z.string() }}
      onDrop={handleDrop}
      className={style.draggableNavItem}
    >
      {(dragAndDropProps) => {
        const isSelected = selectedTextItemIds.includes(textItem._id);
        const parentBlockIsSelected = Boolean(textItem.blockId && selectedBlockId === textItem.blockId);

        return (
          <>
            {dragAndDropProps.isDropTarget && dragAndDropProps.dragLocation === "above" && (
              <div className={classNames(style.dropIndicator, style.textItemDrop)} />
            )}
            <NavItem
              key={textItem._id}
              className={style.navItem}
              type={itemType}
              pressed={isSelected}
              parentSelected={parentBlockIsSelected}
              onClick={(e) => onTextItemClick({ id: textItem._id, richText: textItem.rich_text, e })}
            >
              {textItem.text}
            </NavItem>
            {dragAndDropProps.isDropTarget && dragAndDropProps.dragLocation === "below" && (
              <div className={classNames(style.dropIndicator, style.textItemDrop)} />
            )}
          </>
        );
      }}
    </DragAndDroppable>
  );
}

function MessageNavItem(props: { item: INavMessageItem }) {
  return (
    <div className={classNames(style.hiddenResultsMessageNavItem, style.hiddenResultsChildMessageNavItem)}>
      {props.item.message}
    </div>
  );
}

function HiddenProjectItemsNavItem() {
  const { hiddenProjectItemsMessage } = useAtomValue(flattenedProjectItemsAtom);

  if (!hiddenProjectItemsMessage) {
    return null;
  }

  return <div className={style.hiddenResultsMessageNavItem}>{hiddenProjectItemsMessage}</div>;
}

export default LeftSidebar;
