import classNames from "classnames";
import React, { useMemo } from "react";
import { ZTextItemStatus } from "../../../../shared/types/TextItem";
import Text from "../../atoms/Text";
import DropdownCombobox from "../../molecules/DropdownCombobox";
import OverlappingBubbles, { Bubble } from "../../molecules/OverlappingBubbles";
import style from "./index.module.css";

export type Status = "NONE" | "WIP" | "REVIEW" | "FINAL";

interface IProps {
  value: string[];
  onChange: (value: Status[]) => void;

  onRemoveFilter?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

const statuses = {
  NONE: { value: "NONE", label: "No status" },
  WIP: { value: "WIP", label: "Work in Progress" },
  REVIEW: { value: "REVIEW", label: "Review" },
  FINAL: { value: "FINAL", label: "Final" },
} as const;

const statusOptions = Object.values(statuses);

export function StatusFilterDropdown(props: IProps) {
  function onChange(selectedOptions: { value: Status; label: string }[]) {
    props.onChange(selectedOptions.map((v) => v.value));
  }

  // For flexibility, we don't require that the `values` array be hard-typed to values matching the Status enum
  // To make sure we don't accidentally pass a bad value, we coerce the props to match the Status enum with zod
  const formattedValues = useMemo(() => {
    const safeValues = props.value.filter((v) => ZTextItemStatus.safeParse(v).success) as Status[];
    return safeValues.map((v) => statuses[v]);
  }, [props.value]);

  return (
    <DropdownCombobox
      className={classNames(style.statusFilterDropdown, props.className)}
      style={props.style}
      options={statusOptions}
      showTextInput={false}
      selectedOptions={formattedValues}
      onChange={onChange}
      multiple={true}
      TriggerComponent={TriggerComponent}
      OptionComponent={OptionComponent}
      onRemoveFilter={props.onRemoveFilter}
    />
  );
}

interface ITriggerProps {
  selectedOptions: { value: Status; label: string }[];
}

function TriggerComponent(props: ITriggerProps) {
  return (
    <div className={style.trigger}>
      {props.selectedOptions.length === 0 && (
        <Text color="secondary" weight="light" size="micro">
          Choose status...
        </Text>
      )}
      {props.selectedOptions.length === 1 && (
        <div className={style.selected}>
          <Bubble status={props.selectedOptions[0].value} />
          <Text color="secondary" weight="light" size="micro">
            {props.selectedOptions[0].label}
          </Text>
        </div>
      )}
      {props.selectedOptions.length > 1 && (
        <div className={style.selected}>
          <OverlappingBubbles statuses={props.selectedOptions.slice(0, 3).map((v) => v.value)} />
          <Text color="secondary" weight="light" size="micro">
            {props.selectedOptions.length} statuses
          </Text>
        </div>
      )}
    </div>
  );
}

interface IOptionProps {
  option: { value: Status; label: string };
  query: string;
  selected: boolean;
}

function OptionComponent(props: IOptionProps) {
  return (
    <Text color="primary" size="small" className={style.label}>
      <Bubble status={props.option.value} />
      <span>{props.option.label}</span>
    </Text>
  );
}

export default StatusFilterDropdown;
