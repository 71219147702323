import * as httpDittoProject from "@/http/dittoProject";
import paginatedAtom from "@shared/frontend/stores/paginatedAtom";
import { IFChangeItem } from "@shared/types/ActualChange";
import logger from "@shared/utils/logger";
import { splitAtom, unwrap } from "jotai/utils";
import { projectIdAtom } from "./Project";

const {
  valueAtom: _projectActivityAtom,
  fetchNextPageActionAtom,
  hasMoreAtom,
  loadingAtom,
} = paginatedAtom<IFChangeItem, string | null>({
  dependencyAtom: projectIdAtom,
  pageSize: 20,
  async pageRequest({ page, pageSize }, projectId) {
    try {
      if (!projectId) return [];

      const [request] = httpDittoProject.getActivity({ projectId, page, pageSize });
      const response = await request;
      return response.data;
    } catch (error) {
      logger.error("Failed to fetch project activity", { context: { projectId, page, pageSize } }, error);
      return [];
    }
  },
  debugPrefix: "Project Activity",
});

export const projectActivitySplitAtom = splitAtom(unwrap(_projectActivityAtom, (prev) => prev ?? []));

export const projectActivityAtom = _projectActivityAtom;
export const fetchNextActivityPageActionAtom = fetchNextPageActionAtom;
export const hasMoreActivityAtom = hasMoreAtom;
export const activityLoadingAtom = loadingAtom;
