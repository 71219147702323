import { getTags, getUsers } from "@/http/workspaceNew";
import { IFUser } from "@shared/types/User";
import { atom } from "jotai";
import { atomWithRefresh } from "jotai/utils";

export const usersAtom = atomWithRefresh(() => getUsers()[0].then((r) => r.data));

export const usersByIdAtom = atom(async (get) => {
  const users = await get(usersAtom);
  return users.reduce((acc, user) => {
    acc[user._id] = user;
    return acc;
  }, {} as Record<string, IFUser>);
});

export const allTagsAtom = atomWithRefresh(() =>
  getTags({ excludeWsComps: false, sortAlpha: true, excludeSampleData: true })[0].then((r) => r.data)
);
