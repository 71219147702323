import { searchAtom } from "@/stores/ProjectFiltering";
import Button from "@ds/atoms/Button";
import Icon from "@ds/atoms/Icon";
import TextInput from "@ds/atoms/TextInput";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import style from "./style.module.css";

function FilterBarSearch() {
  const [searchQuery, setSearchQuery] = useAtom(searchAtom);
  const [isSearchActive, setIsSearchActive] = useState(Boolean(searchQuery));

  const resetSearch = useCallback(() => {
    setSearchQuery("");
    setIsSearchActive(false);
  }, [setSearchQuery]);

  const handlePotentialSearchHotkey = useCallback(
    (event: KeyboardEvent) => {
      // metaKey = Cmd on macOS
      // ctrlKey = Ctrl on Windows
      // key 70 = "f"
      // If the user presses Cmd/Ctrl + F, bring up the search bar
      if ((event.metaKey || event.ctrlKey) && event.key === "f") {
        setIsSearchActive((isAlreadySearching) => {
          // If the search bar was already open, allow the browser's default find behavior
          if (!isAlreadySearching) {
            event.preventDefault();
          }
          return true;
        });
      }

      if (event.key === "Escape") {
        resetSearch();
      }
    },
    [resetSearch]
  );

  useEffect(() => {
    window.addEventListener("keydown", handlePotentialSearchHotkey);

    return () => {
      window.removeEventListener("keydown", handlePotentialSearchHotkey);
    };
  }, [handlePotentialSearchHotkey]);

  if (!isSearchActive) {
    return (
      <Button variant="icon" onClick={() => setIsSearchActive(true)}>
        <Icon Icon={<SearchIcon />} size="xs" />
      </Button>
    );
  }

  return (
    <TextInput
      autoFocus
      placeholder="Find..."
      value={searchQuery}
      onChange={(val) => setSearchQuery(val)}
      trailingIcon={
        <Button variant="icon" size="small" onClick={resetSearch}>
          <Icon Icon={<CloseIcon />} size="xs" />
        </Button>
      }
      className={style.searchInput}
    />
  );
}

export default FilterBarSearch;
