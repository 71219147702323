import DocHistory from "@/components/dochistory/dochistory";
import {
  activityLoadingAtom,
  fetchNextActivityPageActionAtom,
  hasMoreActivityAtom,
  projectActivityAtom,
} from "@/stores/ProjectActivity";
import { usersAtom } from "@/stores/Workspace";
import { useAtomValue, useSetAtom } from "jotai";
import { useRef } from "react";
import style from "./style.module.css";

function ActivityPanel() {
  const allActivity = useAtomValue(projectActivityAtom);
  const activityLoading = useAtomValue(activityLoadingAtom);
  const hasMoreActivity = useAtomValue(hasMoreActivityAtom);
  const fetchNextActivityPageAction = useSetAtom(fetchNextActivityPageActionAtom);
  const workspaceUsers = useAtomValue(usersAtom);
  const docHistoryScrollTopRef = useRef(0);
  const commentHistoryScrollTopRef = useRef(0);

  function loadMoreActivity() {
    fetchNextActivityPageAction();
  }

  return (
    <div className={style.wrapper}>
      <DocHistory
        devModeEnabled={true}
        docHistory={allActivity}
        docHistoryLoading={activityLoading}
        setSingleSelected={() => {}}
        setMultiSelected={() => {}}
        docHistoryIndex={undefined}
        loadMoreDocHistory={loadMoreActivity}
        allDocHistFetched={!hasMoreActivity}
        resetDocHistory={() => {}}
        handleCommentChangeClick={() => {}}
        prev_resync_time={0}
        curr_resync_time={1}
        showHighlightNew={false}
        setShowHighlightNew={() => {}}
        handleHistoryUpdate={() => {}}
        handleDocUpdate={() => {}}
        fetchCompInfo={() => {}}
        forceShowToast={() => {}}
        workspaceUsers={workspaceUsers}
        commentState={{ isSelected: false, thread_id: null }}
        handleOpenFramePage={() => {}}
        handleOpenBlockPage={() => {}}
        setScrollToId={() => {}}
        isLockedProject={false}
        onResolveSuggestion={() => {}}
        commentHistoryScrollTopRef={commentHistoryScrollTopRef}
        docHistoryScrollTopRef={docHistoryScrollTopRef}
        activityFilter={undefined}
        setSyncSettingsModalVisible={() => {}}
        findTextItemsInProjectViaProps={() => []}
      />
    </div>
  );
}

export default ActivityPanel;
