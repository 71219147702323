import { detailsPanelEditStateAtom, selectedBlockIdAtom, selectionTypeAtom } from "@/stores/ProjectSelection";
import BlockDetails from "@/views/NS/ProjectNS/components/DetailsPanel/BlockDetails";
import TextItemDetails from "@/views/NS/ProjectNS/components/DetailsPanel/TextItemDetails";
import { useAtom, useAtomValue } from "jotai";
import { Suspense } from "react";
import EditTabBar from "../EditTabBar";
import TextItemActivity from "../TextItemActivity";
import TitleRow from "../TitleRow";
import style from "./style.module.css";

function ProjectDetailsPanel() {
  const selectionType = useAtomValue(selectionTypeAtom);
  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const [selectedTab] = useAtom(detailsPanelEditStateAtom);

  return (
    <>
      <TitleRow />
      <EditTabBar />
      <div className={style.contentWrapper}>
        {selectedTab === "EDIT" && (
          <Suspense fallback={<div>Loading...</div>}>
            {selectionType === "text" && <TextItemDetails />}
            {selectionType === "block" && selectedBlockId && <BlockDetails selectedBlockId={selectedBlockId} />}
          </Suspense>
        )}
        {selectedTab === "ACTIVITY" && (
          <Suspense fallback={<div>Loading...</div>}>
            <TextItemActivity />
          </Suspense>
        )}
      </div>
    </>
  );
}

export default ProjectDetailsPanel;
