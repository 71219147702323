import CompHistory from "@/components/comphistory/comphistory";
import { projectAtom, textItemFamilyAtom } from "@/stores/Project";
import { selectedTextItemIdsAtom } from "@/stores/ProjectSelection";
import { selectedTextItemActivityAtom } from "@/stores/TextItemActivity";
import { usersAtom } from "@/stores/Workspace";
import { useAtom, useAtomValue } from "jotai";
import style from "./style.module.css";

function TextItemActivity() {
  const [activity, refreshActivity] = useAtom(selectedTextItemActivityAtom);
  const project = useAtomValue(projectAtom);
  const users = useAtomValue(usersAtom);
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const selectedTextItem = useAtomValue(textItemFamilyAtom(selectedTextItemIds[0]));

  if (selectedTextItemIds.length !== 1) {
    return null;
  }

  const selectedTextItemId = selectedTextItemIds[0];

  return (
    <div className={style.textItemActivityWrapper}>
      <CompHistory
        isWsComp={false}
        selectedId={selectedTextItemId}
        compHistoryLoading={false}
        origComp={selectedTextItem}
        history={activity}
        doc_ID={project._id}
        doc_name={project.name}
        showSuggestionComponentWarning
        onResolveSuggestion={() => {}}
        selectedFigmaId={null}
        setCompHistory={() => {}}
        handleDocUpdate={refreshActivity}
        handleHistoryUpdate={refreshActivity}
        commentStateThreadId={null}
        setCommentState={null}
        forceShowToast={null}
        fetchCompInfo={() => {}}
        workspaceUsers={users}
        selectedVariant={null}
        setPanelState={null}
        isDraftHistory={false}
        isLockedProject={false}
        setSyncSettingsModalVisible={() => {}}
        hideCommentEditor={true}
        fetchAllComps={undefined}
        refreshLibraryHistory={undefined}
        updateCompResultComments={undefined}
      />
    </div>
  );
}

export default TextItemActivity;
