import CallSplitIcon from "@mui/icons-material/CallSplit";
import classNames from "classnames";
import React from "react";
import Badge from "../../atoms/Badge";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import OverlappingBubbles, { Status } from "../../molecules/OverlappingBubbles";
import style from "./index.module.css";

interface IVariantBadgeProps {
  className?: string;
  style?: React.CSSProperties;

  statuses: Status[];
}

export function VariantBadge(props: IVariantBadgeProps) {
  return (
    <Badge
      className={classNames(style.VariantBadgeWrapper, props.className)}
      type="outline"
      borderRadius="lg"
      size="sm"
      style={props.style}
    >
      <Icon Icon={<CallSplitIcon />} color="secondary" size="xxs" className={style.rotateRight} />
      <Text size="micro" color={props.statuses.length ? "secondary" : "tertiary"}>
        {props.statuses.length}
      </Text>
      {props.statuses.length > 0 && (
        <OverlappingBubbles noneStatusClassName={style.noneStatus} size="sm" statuses={props.statuses} />
      )}
    </Badge>
  );
}

export default VariantBadge;
