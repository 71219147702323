import atomWithURLStorage from "@shared/frontend/stores/atomWithURLStorage";
import { ITextItemPopulatedComments, ITipTapRichText } from "@shared/types/TextItem";
import { atom, WritableAtom } from "jotai";
import { atomWithLocation } from "jotai-location";
import { SetStateAction } from "react";
import { inlineEditingAtom } from "./Editing";
import { projectBlocksSplitAtom, textItemFamilyAtom } from "./Project";

export const locationAtom = atomWithLocation();

const DETAILS_PANEL_PARAM = "detailsPanel";

// MARK: - Source Atoms

// Most selection and filtering states should keep their source of truth in the URL.

type Selection =
  | {
      type: "none";
    }
  | {
      type: "text";
      ids: string[];
    }
  | {
      type: "block";
      id: string;
    };

const TEXT_SELECTION_PARAM = "selectedTextItemIds";
const BLOCK_SELECTION_PARAM = "selectedBlockIds";

/**
 * Special atom type for use only in this file.
 * Stores the current selection state for the project.
 * These selection states are mutually exclusive -- we can have text items selected, or blocks selected, but not both.
 * Any other kind of selection logic should be enforced here as well.
 */
function pageSelectionAtom(): WritableAtom<Selection, [SetStateAction<Selection>], void> {
  const textItemIdsAtom = atomWithURLStorage(TEXT_SELECTION_PARAM, locationAtom);
  // atomWithURLStorage will return an array of strings if the URL param is present, and null if it's not
  // we should probably allow it to just store a string, but for now the code in this atom takes
  // care of string <> array conversion
  const blockIdsAtom = atomWithURLStorage(BLOCK_SELECTION_PARAM, locationAtom);

  const selectionAtom = atom(
    (get) => {
      const textItemIds = get(textItemIdsAtom);
      const blockIds = get(blockIdsAtom);
      // Intentionally throwing a hard error here -- this should never happen, and if it does we should figure out why.
      if (textItemIds && blockIds) {
        throw new Error("Page selection should be mutually exclusive");
      }

      if (textItemIds) {
        return { type: "text", ids: textItemIds } as Selection;
      } else if (blockIds && blockIds.length > 0) {
        return { type: "block", id: blockIds[0] } as Selection;
      } else {
        return { type: "none" } as Selection;
      }
    },
    (get, set, newSelection: Selection) => {
      if (newSelection.type === "none") {
        set(textItemIdsAtom, null);
        set(blockIdsAtom, null);
      } else if (newSelection.type === "text") {
        set(textItemIdsAtom, newSelection.ids);
        set(blockIdsAtom, null);
      } else if (newSelection.type === "block") {
        set(textItemIdsAtom, null);
        set(blockIdsAtom, [newSelection.id]);
      }
    }
  );

  return selectionAtom;
}
export const selectedItemsAtom = pageSelectionAtom();

// MARK: - Derived Atoms

export const selectedTextItemIdsAtom = atom((get) => {
  const selection = get(selectedItemsAtom);
  if (selection.type === "text") {
    return selection.ids;
  } else {
    return [];
  }
});

export const selectedTextItemsAtom = atom(
  async (get) => {
    const ids = get(selectedTextItemIdsAtom);
    const textItems = await Promise.all(ids.map((id) => get(textItemFamilyAtom(id))));
    return textItems;
  },
  (get, set, updatedTextItems: ITextItemPopulatedComments[]) => {
    updatedTextItems.forEach((item) => {
      set(textItemFamilyAtom(item._id), item);
    });
  }
);

export const selectedBlockIdAtom = atom((get) => {
  const selection = get(selectedItemsAtom);
  if (selection.type === "block") {
    return selection.id;
  } else {
    return null;
  }
});

export const selectionTypeAtom = atom((get) => {
  const selection = get(selectedItemsAtom);
  return selection.type;
});

// MARK: - Actions

/**
 * When performing a shift-click or an arrow-shift selection, we need to determine the existing selections that are not
 * contiguous with the new selections. This function takes care of that logic.
 *
 * @param selectedTextItemIds The set of selected text item IDs.
 * @param allItems All item IDs in the project.
 * @param currentIndex The clicked element index, or the 'pointer' index in case of an arrow-shift selection.
 * @param mostRecentlySelectedIndex The 'pivot' index of the shift range selection.
 * @param startIndex Start index of the shift range selection.
 * @param endIndex End index of the shift range selection.
 *
 * Note: `currentIndex` and `endIndex`, as well as `mostRecentlySelectedIndex` and `startIndex`, are designed to account for
 * both mouse-based shift-click selection and keyboard-driven arrow-shift selection. In pure shift-click, these values will
 * typically be equal, as the selection forms a contiguous range. However, if command-click is used to
 * select non-contiguous items, these parameters may differ, since `currentIndex` can point to individual selections and
 * the notion of a continuous range may not apply.
 *
 * @return The elements which are not contiguous with the new selections.
 */
function getPriorSelections(
  selectedTextItemIds: string[],
  allItems: { _id: string; sortKey: string }[],
  currentIndex: number,
  mostRecentlySelectedIndex: number,
  startIndex: number,
  endIndex: number
) {
  /**
   * Contiguous prior selections are selections in the opposite direction of the current selection that are contiguous
   * with the pivot, i.e. the most recently selected item. This is needed to effectively unselect the prior pivot-based
   * selections.
   */
  const contiguousPriorSelectionsFromStart = (() => {
    const priorSelections: string[] = [];

    const seekDirection = currentIndex < mostRecentlySelectedIndex ? 1 : -1;

    for (let i = mostRecentlySelectedIndex; i >= 0 && i < allItems.length; i += seekDirection) {
      const id = allItems[i]._id;
      if (selectedTextItemIds.includes(id)) {
        priorSelections.push(id);
      } else {
        break;
      }
    }

    return new Set(priorSelections);
  })();

  const contiguousPriorSelectionsFromEnd = (() => {
    const priorSelections: string[] = [];

    const seekDirection = currentIndex < mostRecentlySelectedIndex ? -1 : 1;

    for (let i = currentIndex; i >= 0 && i < allItems.length; i += seekDirection) {
      const id = allItems[i]._id;
      if (selectedTextItemIds.includes(id)) {
        priorSelections.push(id);
      } else {
        break;
      }
    }

    return new Set(priorSelections);
  })();

  const allItemsIdToIndexMap = new Map(allItems.map((item, index) => [item._id, index]));

  // If there are selections present that are not between the start and end indices, include them in the new selections,
  // unless they are contiguous with the start or end indices.
  const alreadyPresentSelections = selectedTextItemIds
    .filter((id) => {
      const index = allItemsIdToIndexMap.get(id);
      return index !== undefined && (index < startIndex || index >= endIndex);
    })
    .filter((id) => !contiguousPriorSelectionsFromStart.has(id) && !contiguousPriorSelectionsFromEnd.has(id));

  return alreadyPresentSelections;
}

export const onTextItemClickActionAtom = atom(
  null,
  async (get, set, { e, ...textItem }: { id: string; richText: ITipTapRichText; e: React.MouseEvent<HTMLElement> }) => {
    const selectedTextItemIds = get(selectedTextItemIdsAtom);
    const isSelected = selectedTextItemIds.includes(textItem.id);
    const isInlineEditing = !!get(inlineEditingAtom);

    if (e.shiftKey) {
      // We need the ordered list of all text items to determine the range of elements to select.
      const allItems = get(projectBlocksSplitAtom).flatMap((projectBlockAtom) => get(projectBlockAtom).textItems);

      // If we press SHIFT+click, select the range of elements between the most recently selected element and the current one.
      const mostRecentlySelectedIndex = allItems.findIndex(
        (item) => item._id === selectedTextItemIds[selectedTextItemIds.length - 1]
      );

      // If the last selected element is not found (should never happen), select the current element.
      if (mostRecentlySelectedIndex === -1) {
        set(setSelectedTextIdsActionAtom, [textItem.id]);
        set(inlineEditingAtom, null);
        return;
      }

      const currentIndex = allItems.findIndex((item) => item._id === textItem.id);
      const startIndex = Math.min(mostRecentlySelectedIndex, currentIndex);
      const endIndex = Math.max(mostRecentlySelectedIndex, currentIndex);

      const alreadyPresentSelections = getPriorSelections(
        selectedTextItemIds,
        allItems,
        currentIndex,
        mostRecentlySelectedIndex,
        startIndex,
        endIndex
      );

      // Create the new selections array and ensure the most recently selected element is preserved at the end.
      const newSelections = [
        ...alreadyPresentSelections,
        ...allItems.slice(startIndex, mostRecentlySelectedIndex).map((item) => item._id),
        ...allItems
          .slice(mostRecentlySelectedIndex, endIndex + 1)
          .map((item) => item._id)
          .reverse(),
      ];

      set(setSelectedTextIdsActionAtom, newSelections);
      set(inlineEditingAtom, null);
    } else if (e.metaKey) {
      // If we press CMD+click, toggle selection of the element, without affecting other selections.
      if (isSelected) {
        set(
          setSelectedTextIdsActionAtom,
          selectedTextItemIds.filter((id) => id !== textItem.id)
        );
      } else {
        // Place the text item twice in the selections array to properly handle both pivot and pointer state (relevant for arrow-shift selection)
        set(setSelectedTextIdsActionAtom, [textItem.id, ...selectedTextItemIds, textItem.id]);
      }
      set(inlineEditingAtom, null);
    } else {
      // If we plain-select a selected element, turn on inline editing and remove other selections.
      if (isSelected && !isInlineEditing) {
        set(inlineEditingAtom, { type: "existing", id: textItem.id, richText: textItem.richText });
        set(setSelectedTextIdsActionAtom, [textItem.id]);
        return;
      }

      // If we plain-select a new element, remove all other selections.
      if (!isSelected) {
        set(setSelectedTextIdsActionAtom, [textItem.id]);
        set(inlineEditingAtom, null);
        return;
      }
    }
  }
);

export const onTextItemKeyDownActionAtom = atom(null, (get, set, event: React.KeyboardEvent<HTMLDivElement>) => {
  if (event.key !== "ArrowUp" && event.key !== "ArrowDown") {
    return;
  }

  if (event.key === "ArrowUp" || event.key === "ArrowDown") {
    event.preventDefault();
  }

  const selectedTextItemIds = get(selectedTextItemIdsAtom);
  const allItems = get(projectBlocksSplitAtom).flatMap((projectBlockAtom) => get(projectBlockAtom).textItems);

  // If no items are selected, do nothing.
  if (selectedTextItemIds.length === 0) {
    return;
  }

  // If we press a plain-arrow key, select the next/previous item in the list and discard all other selections.
  if (!event.shiftKey) {
    const currentIndex = allItems.findIndex((item) => item._id === selectedTextItemIds[selectedTextItemIds.length - 1]);
    const newIndex = event.key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;

    // If the new index is out of bounds, set the selection to only the most recently selected item.
    if (newIndex < 0 || newIndex >= allItems.length) {
      set(setSelectedTextIdsActionAtom, [selectedTextItemIds[selectedTextItemIds.length - 1]]);
      return;
    }

    set(setSelectedTextIdsActionAtom, [allItems[newIndex]._id]);
  } else {
    // If we press SHIFT+arrow-up/down, select the range of elements between the most recently selected element and the current one.
    const mostRecentlySelectedIndex = allItems.findIndex(
      (item) => item._id === selectedTextItemIds[selectedTextItemIds.length - 1]
    );

    // Always store the "pointer" index as the first element in the selection array. The resultant array will be between the
    // new pointer index and the most recently selected index.
    const currentIndex = allItems.findIndex((item) => item._id === selectedTextItemIds[0]);

    // The new current index should be the index of the item that is currently selected, plus or minus the direction of the arrow key,
    // excluding bounds, and excluding the most recently selected index.
    const newCurrentIndex = (() => {
      const initialNewIndex = event.key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;

      if (initialNewIndex < 0 || initialNewIndex >= allItems.length) {
        return currentIndex;
      }

      if (initialNewIndex === mostRecentlySelectedIndex) {
        const skippedIndex = event.key === "ArrowUp" ? currentIndex + 1 : currentIndex - 1;

        if (skippedIndex < 0 || skippedIndex >= allItems.length) {
          return currentIndex;
        }
      }

      return initialNewIndex;
    })();

    const startIndex = Math.min(mostRecentlySelectedIndex, newCurrentIndex);
    const endIndex = Math.max(mostRecentlySelectedIndex, newCurrentIndex);

    const alreadyPresentSelections = getPriorSelections(
      selectedTextItemIds,
      allItems,
      currentIndex,
      mostRecentlySelectedIndex,
      startIndex,
      endIndex
    );

    const newPointerId = allItems[newCurrentIndex]._id;

    // Create the new selections array and ensure the most recently selected element is preserved at the end.
    set(setSelectedTextIdsActionAtom, [
      newPointerId,
      ...alreadyPresentSelections,
      ...allItems.slice(startIndex, mostRecentlySelectedIndex).map((item) => item._id),
      ...allItems
        .slice(mostRecentlySelectedIndex, endIndex + 1)
        .map((item) => item._id)
        .reverse(),
    ]);
  }
});

export const onTextItemTextChangeActionAtom = atom(
  null,
  (get, set, textItem: { id: string; richText: ITipTapRichText }) => {
    const inlineEditingState = get(inlineEditingAtom);
    if (inlineEditingState?.type !== "existing" || inlineEditingState.id !== textItem.id) {
      throw new Error("Invalid state: cannot update text for a text item that is not being edited");
    }

    set(inlineEditingAtom, { type: "existing", id: textItem.id, richText: textItem.richText });
  }
);

export const onTextItemCancelEditActionAtom = atom(null, (get, set, textItemId: string) => {
  const inlineEditingState = get(inlineEditingAtom);
  if (inlineEditingState?.type !== "existing" || inlineEditingState.id !== textItemId) {
    throw new Error("Invalid state: cannot cancel edit for a text item that is not being edited");
  }

  set(inlineEditingAtom, null);
});

export const clearSelectionActionAtom = atom(null, (_, set) => {
  set(selectedItemsAtom, { type: "none" });
  set(inlineEditingAtom, null);
});

export const setSelectedTextIdsActionAtom = atom(null, (_, set, textItemIds: string[]) => {
  set(selectedItemsAtom, { type: "text", ids: textItemIds });
});

export const setSelectedBlockIdsActionAtom = atom(null, (_, set, blockId: string) => {
  set(selectedItemsAtom, { type: "block", id: blockId });
});

export const onClickBlockActionAtom = atom(null, (_, set, blockId: string) => {
  set(inlineEditingAtom, null);
  set(selectedItemsAtom, { type: "block", id: blockId });
});

// MARK: - Details Panel

const DetailsPanelContexts = ["PROJECT", "EDIT"] as const;
export type DetailsPanelContext = (typeof DetailsPanelContexts)[number];

export const detailsPanelContextAtom = atom<DetailsPanelContext>((get) => {
  const block = get(selectedBlockIdAtom);
  const textItems = get(selectedTextItemIdsAtom);

  if (textItems.length > 0) {
    return "EDIT";
  }

  if (block) {
    return "EDIT";
  }

  return "PROJECT";
});

const DetailsPanelEditStates = ["EDIT", "ACTIVITY", "VARIANTS"] as const;
export type DetailsPanelEditStates = (typeof DetailsPanelEditStates)[number];

export const detailsPanelEditStateAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "EDIT";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as DetailsPanelEditStates;

    if (state === null || !DetailsPanelEditStates.includes(state)) {
      return "EDIT";
    }

    return state;
  },
  (get, set, newPanel: DetailsPanelEditStates) => {
    const location = get(locationAtom);
    const newSearchParams = new URLSearchParams(location.searchParams);

    if (newPanel) {
      newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
    } else {
      newSearchParams.delete(DETAILS_PANEL_PARAM);
    }

    set(locationAtom, {
      ...location,
      searchParams: newSearchParams,
    });
  }
);

const DetailsPanelProjectStates = ["ACTIVITY", "COMMENTS"] as const;
export type DetailsPanelProjectStates = (typeof DetailsPanelProjectStates)[number];

export const detailsPanelProjectStateAtom = atom(
  (get) => {
    const location = get(locationAtom);

    if (!location.searchParams) {
      return "ACTIVITY";
    }

    const state = location.searchParams.get(DETAILS_PANEL_PARAM) as DetailsPanelProjectStates;

    if (state === null || !DetailsPanelProjectStates.includes(state)) {
      return "ACTIVITY";
    }

    return state;
  },
  (get, set, newPanel: DetailsPanelProjectStates) => {
    const location = get(locationAtom);
    const newSearchParams = new URLSearchParams(location.searchParams);

    if (newPanel) {
      newSearchParams.set(DETAILS_PANEL_PARAM, newPanel);
    } else {
      newSearchParams.delete(DETAILS_PANEL_PARAM);
    }

    set(locationAtom, {
      ...location,
      searchParams: newSearchParams,
    });
  }
);
