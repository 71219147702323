import {
  addNewTextItemActionAtom,
  cancelNewTextItemActionAtom,
  inlineEditingAtom,
  reorderTextItemsActionAtom,
  saveNewTextItemActionAtom,
} from "@/stores/Editing";
import {
  projectTextItemsCountAtom,
  renameBlockActionAtom,
  shiftBlockOrderActionAtom,
  variantsAtom,
} from "@/stores/Project";
import { searchAtom } from "@/stores/ProjectFiltering";
import { onClickBlockActionAtom, selectedBlockIdAtom } from "@/stores/ProjectSelection";
import Button from "@ds/atoms/Button";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import TextItem from "@ds/molecules/TextItem";
import TextItemBlock from "@ds/molecules/TextItemBlock";
import { IVariantTab, VariantTabs } from "@ds/molecules/VariantTabs";
import useVariantTabs from "@ds/molecules/VariantTabs/useVariantTabs";
import { BASE_VARIANT_ID } from "@shared/types/FigmaVariables";
import { IFDittoProjectData } from "@shared/types/http/DittoProject";
import { Atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useState } from "react";
import { z } from "zod";
import TextItemRow from "../TextItemRow";
import style from "./style.module.css";

function clamp(val: number, min: number, max: number) {
  return Math.min(Math.max(val, min), max);
}

function TextItemBlockWrapper(props: {
  textItemBlockAtom: Atom<IFDittoProjectData["blocks"][number]>;
  index: number;
  maxIndex: number;
}) {
  const textItemBlock = useAtomValue(props.textItemBlockAtom);

  const variants = useAtomValue(variantsAtom);
  const [projectContentSearchQuery] = useAtom(searchAtom);
  const numTextItems = useAtomValue(projectTextItemsCountAtom);
  const inlineEditingState = useAtomValue(inlineEditingAtom);

  const addNewTextItemAction = useSetAtom(addNewTextItemActionAtom);
  const saveNewTextItemAction = useSetAtom(saveNewTextItemActionAtom);
  const cancelNewTextItemAction = useSetAtom(cancelNewTextItemActionAtom);

  const selectedBlockId = useAtomValue(selectedBlockIdAtom);
  const onClickBlockAction = useSetAtom(onClickBlockActionAtom);
  const renameBlockAction = useSetAtom(renameBlockActionAtom);
  const shiftBlockOrderAction = useSetAtom(shiftBlockOrderActionAtom);

  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);

  const [activeVariantIndex, setActiveVariantIndex] = useState(0);
  const [showAllTextItems, setShowAllTextItems] = useState(false);

  // selection state
  const isSelected = textItemBlock._id && selectedBlockId === textItemBlock._id;

  // variants
  const variantsInBlock: IVariantTab[] = [];

  if (textItemBlock.variantIds && textItemBlock.variantIds.length) {
    variantsInBlock.push({ name: "Base", id: BASE_VARIANT_ID });
    textItemBlock.variantIds.forEach((variantId) => {
      const match = variants.find((variant) => variant._id.toString() === variantId);
      if (match) variantsInBlock.push({ name: match.name, id: match._id });
    });
  }

  const { variantTabs, activeVariant, getVariantIndexById } = useVariantTabs({
    variants: variantsInBlock,
    activeVariantIndex,
  });

  // filtering
  const emptyProject = numTextItems === 0;
  const numHiddenResultsTextItems = textItemBlock.allTextItems.length - textItemBlock.textItems.length;
  const textItemType = showAllTextItems ? "allTextItems" : "textItems";

  function handleRenameBlock(name: string) {
    if (textItemBlock._id) renameBlockAction(textItemBlock._id, name);
  }

  function onTabClick(tab: IVariantTab) {
    setActiveVariantIndex(getVariantIndexById(tab.id));
  }

  if (textItemBlock._id === null) {
    const isCreatingNewTextItem =
      !emptyProject && inlineEditingState?.type === "new" && inlineEditingState.blockId === null;
    const renderTextItemsNotInBlock = textItemBlock.textItems.length > 0 || isCreatingNewTextItem;

    return (
      <>
        {renderTextItemsNotInBlock && (
          <div className={style.textItemsNotInBlock}>
            {textItemBlock.textItems.map((textItem) => (
              <TextItemRow key={textItem._id} textItemId={textItem._id} activeVariant={activeVariant} />
            ))}
            {isCreatingNewTextItem && (
              <TextItem
                autoFocus
                onClickSave={saveNewTextItemAction}
                onClickCancel={cancelNewTextItemAction}
                editState="editing"
              />
            )}
          </div>
        )}
      </>
    );
  }

  function handleDrop(textItemIds: string[], dragLocation: "above" | "below" | null) {
    reorderTextItemsAction([
      {
        textItemIds: textItemIds,
        blockId: textItemBlock._id,
      },
    ]);
  }

  const addingTextItem = inlineEditingState?.type === "new" && inlineEditingState.blockId === textItemBlock._id;

  return (
    <DragAndDroppable draggableItems={[]} allowedItemKeys={{ "ditto/textItem": z.string() }} onDrop={handleDrop}>
      {(dragAndDropProps) => {
        return (
          <div className={style.textItemBlockAndVariants}>
            {variantsInBlock.length > 0 && (
              <VariantTabs variantTabs={variantTabs} activeVariant={activeVariant} onTabClick={onTabClick} />
            )}
            <TextItemBlock
              isEmpty={!addingTextItem && textItemBlock.allTextItems.length === 0}
              onClickBlock={() => {
                if (textItemBlock._id) {
                  onClickBlockAction(textItemBlock._id);
                }
              }}
              name={textItemBlock.name ?? "Block"}
              onSaveName={handleRenameBlock}
              highlightedPhrase={projectContentSearchQuery}
              state={isSelected ? "focus" : "default"}
              onMoveBlockUp={() => shiftBlockOrderAction({ blockId: textItemBlock._id, direction: "up" })}
              onMoveBlockDown={() => shiftBlockOrderAction({ blockId: textItemBlock._id, direction: "down" })}
              canMoveDown={props.index < props.maxIndex}
              canMoveUp={props.index > 0}
              onAddNewTextItem={() => addNewTextItemAction(textItemBlock._id)}
            >
              {dragAndDropProps.isDropTarget && dragAndDropProps.dragLocation === "above" && (
                <div className={style.dropIndicatorWrapper}>
                  <div className={style.dropIndicator} />
                </div>
              )}
              <div className={style.textItems}>
                {textItemBlock[textItemType].map((textItem) => (
                  <TextItemRow key={textItem._id} textItemId={textItem._id} activeVariant={activeVariant} />
                ))}
              </div>
              {dragAndDropProps.isDropTarget && dragAndDropProps.dragLocation === "below" && (
                <div className={style.dropIndicatorWrapper}>
                  <div className={style.dropIndicator} />
                </div>
              )}
              {inlineEditingState?.type === "new" && inlineEditingState.blockId === textItemBlock._id && (
                <TextItem
                  autoFocus
                  onClickSave={saveNewTextItemAction}
                  onClickCancel={cancelNewTextItemAction}
                  editState="editing"
                />
              )}
              {numHiddenResultsTextItems > 0 && !showAllTextItems && (
                <div className={style.hiddenResultsMessageBlock}>
                  <div>
                    {numHiddenResultsTextItems} more text {numHiddenResultsTextItems === 1 ? "item" : "items"} in this
                    block not shown in search result
                  </div>
                  <div className={style.hiddenResultsMessageBlockDivider}>•</div>
                  <Button variant="text" size="base" onClick={() => setShowAllTextItems(true)}>
                    Show all
                  </Button>
                </div>
              )}
            </TextItemBlock>
          </div>
        );
      }}
    </DragAndDroppable>
  );
}

export default TextItemBlockWrapper;
