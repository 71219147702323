import { useMemo } from "react";

interface IUseVariantTabsArgs {
  variants: {
    name: string;
    id: string;
  }[];
  activeVariantIndex: number;
}

function useVariantTabs(args: IUseVariantTabsArgs) {
  const { variants, activeVariantIndex } = args;

  const activeVariant = useMemo(() => {
    if (!variants || activeVariantIndex > variants.length - 1) return { id: "__base__", name: "Base" };

    if (activeVariantIndex === -1) {
      return { id: "__base__", name: "Base" };
    }

    return variants[activeVariantIndex];
  }, [variants, activeVariantIndex]);

  // TODO: fill this out with custom functionality to handle border resizing
  // and how we want to display collapsed tabs (i.e. "1 other variant")
  const variantTabs = useMemo(() => {
    return variants;
  }, [variants]);

  const getVariantIndexById = (variantId: string) => {
    const variantIndex = variants?.findIndex((v) => v.id === variantId) ?? -1;

    return variantIndex;
  };

  return {
    activeVariant,
    variantTabs,
    getVariantIndexById,
  };
}

export default useVariantTabs;
