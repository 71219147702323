import z from "zod";
import { BackendSchema, FrontendSchema, ZObjectId } from "./lib";
import { SlackNotificationTypes } from "./SlackNotifications";
import { ZTextItemPluralType, ZTextItemStatus, ZTipTapRichText } from "./TextItem";

export const ZDittoProjectBlock = z.object({
  _id: ZObjectId,
  name: z.string(),
  frameCount: z.number(),
});

export type IDittoProjectBlock = z.infer<typeof ZDittoProjectBlock>;
export type IFDittoProjectBlock = FrontendSchema<IDittoProjectBlock>;
export type IBDittoProjectBlock = BackendSchema<IDittoProjectBlock>;

// Type for allowing partial updates to a block; all fields are optional excpet for _id.
export const ZDittoProjectBlockUpdate = ZDittoProjectBlock.partial().extend({
  _id: z.string(),
});
export type IDittoProjectBlockUpdate = z.infer<typeof ZDittoProjectBlockUpdate>;
export type IFDittoProjectBlockUpdate = FrontendSchema<IDittoProjectBlockUpdate>;
export type IBDittoProjectBlockUpdate = BackendSchema<IDittoProjectBlockUpdate>;

export const ZDittoProject = z.object({
  _id: ZObjectId,
  workspaceId: ZObjectId,
  name: z.string(),
  integrations: z.object({
    figma: z
      .object({
        fileId: z.string().optional(), // referencing the FigmaFile collection
        branchId: z.string().nullable(),
        dittoComponentKey: z.string().optional(),
        text_layer_rules: z
          .object({
            show_component_icon: z.boolean(), // probably will remove this later
            show_status_icon: z.boolean(),
            show_api_id: z.boolean(),
          })
          .optional(),
      })
      .optional(),
    slack: z
      .object({
        channel_id: z.string().nullable(),
        channel_name: z.string().nullable(),
        webhook_url: z.string().nullable(),
        configuration_url: z.string().nullable(),
        notif_types: SlackNotificationTypes,
      })
      .optional(),
  }),
  blocks: z.array(ZDittoProjectBlock),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type IDittoProject = z.infer<typeof ZDittoProject>;
export type IFDittoProject = FrontendSchema<IDittoProject>;
export type IBDittoProject = BackendSchema<IDittoProject>;

export const ZTextItemsUpdate = z.object({
  textItemIds: z.array(z.string()),
  richText: ZTipTapRichText.optional(),
  plurals: z
    .array(
      z.object({
        rich_text: ZTipTapRichText,
        form: ZTextItemPluralType,
      })
    )
    .nullable()
    .optional(),
  status: ZTextItemStatus.optional(),
  assignee: z.string().nullable().optional(),
  tags: z.array(z.string()).optional(),
  notes: z.string().optional(),
  characterLimit: z.number().nullable().optional(),
});

export type ITextItemUpdate = z.infer<typeof ZTextItemsUpdate>;
