import classNames from "classnames";
import React, { forwardRef } from "react";
import style from "./index.module.css";

export type TextSize = "base" | "small" | "micro" | "large";

export interface ITextProps extends React.HTMLAttributes<HTMLSpanElement> {
  children: React.ReactNode;
  color?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "placeholder"
    | "invert"
    | "code"
    | "action"
    | "purple"
    | "positive"
    | "warning"
    | "danger"
    | "info"
    | "template";
  weight?: "light" | "base" | "medium" | "strong";
  className?: string;
  size?: TextSize;
  disabled?: boolean;
}

// TODO: typesafe text variations for different headers, font weights, etc
const Text = forwardRef<HTMLSpanElement, ITextProps>(function Text(props, forwardedRef) {
  const { children, disabled = false, weight = "base", ...rest } = props;

  const size = props.size || "base";
  const color = props.color || "primary";

  return (
    <span
      {...rest}
      ref={forwardedRef}
      className={classNames(style.TextWrapper, props.className, {
        [style[`size-${size}`]]: size,
        [style["disabled"]]: disabled,
        [style[`color-${color}`]]: color,
        [style[`weight-${weight}`]]: weight,
      })}
      data-testid="text"
    >
      {children}
    </span>
  );
});

export default Text;
