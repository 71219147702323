import { z } from "zod";
import { ZFigmaTextNode } from "./FigmaTextNode";
import { BackendSchema, FrontendSchema } from "./lib";
import { ZTextItem, ZTipTapRichText } from "./TextItem";

// MARK: - Concerns

const ZTextMismatchConcern = z.object({
  type: z.literal("textMismatch"),
  figmaTextNode: ZFigmaTextNode,
  textItem: ZTextItem,
  dittoRichText: ZTipTapRichText,
  figmaRichText: ZTipTapRichText,
  isConflict: z.boolean(),
});

type ITextMismatchConcern = z.infer<typeof ZTextMismatchConcern>;
export type IBTextMismatchConcern = BackendSchema<ITextMismatchConcern>;
export type IFTextMismatchConcern = FrontendSchema<ITextMismatchConcern>;

export const ZConcern = z.discriminatedUnion("type", [ZTextMismatchConcern]);
type IConcern = z.infer<typeof ZConcern>;
export type IBConcern = BackendSchema<IConcern>;
export type IFConcern = FrontendSchema<IConcern>;

// MARK: - Actions

const ZUpdateFigmaNodeRichTextChange = z.object({
  type: z.literal("richText"),
  before: ZTipTapRichText,
  after: ZTipTapRichText,
});

const ZUpdateFigmaNodeChange = z.discriminatedUnion("type", [ZUpdateFigmaNodeRichTextChange]);

type IUpdateFigmaNodeChange = z.infer<typeof ZUpdateFigmaNodeChange>;
export type IBUpdateFigmaNodeChange = BackendSchema<IUpdateFigmaNodeChange>;
export type IFUpdateFigmaNodeChange = FrontendSchema<IUpdateFigmaNodeChange>;

const ZUpdateFigmaNodeAction = z.object({
  type: z.literal("updateFigmaNode"),
  nodeId: z.string(),
  textItemId: z.string(),
  changes: z.array(ZUpdateFigmaNodeChange),
});

type IUpdateFigmaNodeAction = z.infer<typeof ZUpdateFigmaNodeAction>;
export type IBUpdateFigmaNodeAction = BackendSchema<IUpdateFigmaNodeAction>;
export type IFUpdateFigmaNodeAction = FrontendSchema<IUpdateFigmaNodeAction>;

export const ZAction = z.discriminatedUnion("type", [ZUpdateFigmaNodeAction]);
type IAction = z.infer<typeof ZAction>;
export type IBAction = BackendSchema<IAction>;
export type IFAction = FrontendSchema<IAction>;

// MARK: - Sync results after applying the changes in the figma plugin.

export const ZUpdateTextNodeInstanceLastReconciledRichText = z.object({
  textItemId: z.string(),
  figmaNodeId: z.string(),
  lastReconciledRichText: ZTipTapRichText.nullable(),
});

export type IUpdateTextNodeInstanceLastReconciledRichText = z.infer<
  typeof ZUpdateTextNodeInstanceLastReconciledRichText
>;
