// import { BubbleMenu, EditorContent, FloatingMenu, useEditor } from "@tiptap/react";
import { EditorContent, useEditor } from "@tiptap/react";
import classNames from "classnames";
import React, { useEffect, useMemo } from "react";
import getExtensions from "../../../../shared/frontend/richText/templates";
import { ITipTapRichText } from "../../../../shared/types/TextItem";
import style from "./index.module.css";

interface IProps {
  style?: React.CSSProperties;
  className?: string;

  autoFocus?: boolean;
  content?: ITipTapRichText;
  editable?: boolean; // determines whether or not the user can click directly into the text area
  editing?: boolean; // when this goes from true -> false, causes the text area value to reset
  placeholder?: string;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onTextChange?: (richText: ITipTapRichText) => void;
}

export function EditableTextArea(props: IProps) {
  const extensions = useMemo(
    () =>
      getExtensions({
        placeholder: props.placeholder,
      }),
    [props.placeholder]
  );

  const editor = useEditor(
    {
      extensions: extensions,
      editorProps: {
        attributes: {
          class: `${style.editorContainer}`,
          "data-rich-text": "enabled",
          "data-suberscript": "enabled",
          "data-testid": props.editable ? "text-item-input" : "text-item-input-disabled",
        },
      },
      autofocus: props.autoFocus ?? false, // Note: Undefined has weird behavior, so defaulting to false
      content: props.content,
      onUpdate({ editor }) {
        props.onTextChange?.(editor.getJSON() as ITipTapRichText);
      },
    },
    [extensions, props.content]
  );

  useEffect(
    function clearContentOnEditingDisabled() {
      if (!editor) {
        return;
      }

      if (!props.editing && props.content) {
        editor.commands.setContent(props.content!);
        editor.commands.blur();
      }
    },
    [editor, props.editing, props.content]
  );

  return (
    <div style={{ position: "relative" }}>
      {/* This allows for disabling editing without re-creating the TipTap editor when editability changes, which
          often causes a noticeable flicker */}
      {!props.editable && <div className={style.readOnlyOverlay} />}
      <EditorContent
        className={classNames(style.editorContent, props.className)}
        editor={editor}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
      />
    </div>
  );
}

export default EditableTextArea;
