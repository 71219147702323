import Check from "@mui/icons-material/Check";
import * as RCheckbox from "@radix-ui/react-checkbox";
import classNames from "classnames";
import React from "react";
import style from "./index.module.css";

interface ICheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;

  id?: string;
  disabled?: boolean;
  size?: "base" | "sm" | "xs";
  color?: "primary" | "invert";
  className?: string;
  style?: React.CSSProperties;
}

export function Checkbox(props: ICheckboxProps) {
  const size = props.size ?? "base";
  const color = props.color ?? "primary";

  return (
    <RCheckbox.Root
      style={props.style}
      className={classNames(style.checkbox, props.className, {
        [style.disabled]: props.disabled,
        [style.sm]: size === "sm",
        [style.xs]: size === "xs",
        [style[`color-${color}`]]: color,
      })}
      checked={props.checked}
      onCheckedChange={props.onChange}
      disabled={props.disabled}
      data-testid="checkbox"
      id={props.id}
    >
      <RCheckbox.Indicator className={style.checkboxIndicator}>
        <Check />
      </RCheckbox.Indicator>
    </RCheckbox.Root>
  );
}

export default Checkbox;
