import { detailsPanelEditStateAtom } from "@/stores/ProjectSelection";
import Tabs from "@ds/molecules/Tabs";
import { useAtom } from "jotai";
import { useMemo } from "react";
import style from "./style.module.css";

const tabs = [
  {
    id: "EDIT",
    label: "Edit",
  },
  {
    id: "ACTIVITY",
    label: "Activity",
  },
  {
    id: "VARIANTS",
    label: "Variants",
  },
];
function EditTabBar() {
  const [selectedTab, setSelectedTab] = useAtom(detailsPanelEditStateAtom);

  const selectedTabValue = useMemo(() => {
    let matchingTab = tabs.find((tab) => tab.id === selectedTab);
    if (matchingTab) {
      return matchingTab.id;
    }
    return "EDIT";
  }, [selectedTab]);

  return (
    <div className={style.editTabBarContainer}>
      <Tabs
        value={selectedTabValue}
        onValueChange={setSelectedTab}
        tabs={tabs}
        className={style.tabBar}
        listClassName={style.tabList}
        triggerClassName={style.tab}
      />
      <div className={style.spacer} />
    </div>
  );
}

export default EditTabBar;
