import { WEBSOCKET_EVENTS } from "@shared/common/constants";
import { WEBSOCKET_URL } from "@shared/types/websocket";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { atomFamily } from "jotai/utils";
import { projectIdAtom } from "./Project";
import { userTokenAtom } from "./User";

interface WsMessage {
  messageType: string;
  data?: object;
}

// A family of atoms keyed by messageType and will
// update when a message is received via websockets
// with that messageType
// The member atoms are always set right after initializing
// since they need to represent the last message received
export const wsLastMessagesFamilyAtom = atomFamily((messageType: string) => atom<WsMessage>({ messageType, data: {} }));

interface TextChangedMessage {
  id: string;
  timestamp?: number;
}

// A family of atoms keyed by textItemId that will update
// when a websocket message with the TEXT_ITEMS_UPDATED messageType
// is received
// The member atoms are always set right after initializing
// since they need to represent the last message received
export const textItemChangedFamilyAtom = atomFamily((id: string) => atom(Date.now()));

// Will listen for websocket message and update wsLastMessagesFamilyAtom
// as well as any specific atoms that need websocket updates
// TODO: Integrate with ditto events: https://linear.app/dittowords/issue/DIT-8048/update-jotai-websocket-code-to-integrate-with-ditto-events
export const wsLastMessageEffectAtom = atomEffect((get, set) => {
  const ws = new WebSocket(WEBSOCKET_URL);
  const projectId = get(projectIdAtom);
  const token = get(userTokenAtom);

  ws.onopen = () => {
    ws.send(
      JSON.stringify({
        messageType: WEBSOCKET_EVENTS.NEW_DOC_SUBSCRIPTION,
        token,
        docId: projectId,
      })
    );
  };

  ws.onerror = (error) => {
    console.error(error);
  };

  ws.onmessage = (message) => {
    const event = JSON.parse(message.data);
    const newMessageAtom = wsLastMessagesFamilyAtom(event);
    set(newMessageAtom, event);

    if (event.messageType === WEBSOCKET_EVENTS.TEXT_ITEMS_UPDATED) {
      const textItemIds: string[] = event.textItemIds;
      for (const textItemId of textItemIds) {
        const textChangedAtom = textItemChangedFamilyAtom(textItemId);
        set(textChangedAtom, Date.now());
      }
    }

    newMessageAtom.debugLabel = event.messageType;
  };
});
