import { Mention } from "@/components/comment-editor/comment-editor";
import CommentEditorEditPanel from "@/components/comment-editor/CommentEditorEditPanel";
import http, { API } from "@/http";
import { projectAtom } from "@/stores/Project";
import { detailsPanelEditStateAtom, selectedTextItemIdsAtom } from "@/stores/ProjectSelection";
import { selectedTextItemActivityAtom, unresolvedCommentThreadsAtom } from "@/stores/TextItemActivity";
import { usersAtom } from "@/stores/Workspace";
import { useAtomValue, useSetAtom } from "jotai";
import style from "./style.module.css";

function Comments() {
  const users = useAtomValue(usersAtom);
  const project = useAtomValue(projectAtom);
  const setDetailsPanelEditState = useSetAtom(detailsPanelEditStateAtom);
  const unresolvedCommentThreads = useAtomValue(unresolvedCommentThreadsAtom);
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);
  const refreshSelectedTextItemActivity = useSetAtom(selectedTextItemActivityAtom);

  const loadCommentThreads = async (selectedThreadId?: string) => {
    if (!project) {
      return [];
    }

    const { url } = API.comments.get.unresolvedProjectComments;
    const { data: commentThreads } = await http.get(url(project._id), {
      params: { selectedThreadId },
    });
    return commentThreads;
  };

  const onSaveComment = async (commentText: string, mentionedUsers: Mention[], resetEditorState: () => void) => {
    if (!project) {
      return;
    }

    const { url, body } = API.comments.post.postCompThread;
    const requestBody = body({
      doc_id: project._id,
      doc_name: project.name,
      first_comment: commentText,
      figma_node_id: null,
      mentionedUserIds: mentionedUsers.map((user) => user.userId),
      comp_id: selectedTextItemIds[0],
      from: "web_app",
      ws_comp_id: null,
      suggestion: null,
    });
    await http.post(url, requestBody);
    resetEditorState();
    refreshSelectedTextItemActivity();
  };

  return (
    <div className={style.wrapper}>
      <CommentEditorEditPanel
        doc_name={project?.name}
        // TODO: Implement quick reply mode https://linear.app/dittowords/issue/DIT-8069/comments-quick-reply-mode
        quickReplyMode={{
          enabled: false,
          initialThreadId: undefined,
          goBackState: {},
        }}
        onExitQuickReplyMode={() => {}}
        onQuickReplyNavigation={() => {}}
        onQuickReplyBackArrowClick={() => {}}
        // @ts-ignore something is wrong with this type...
        latestCommentThread={unresolvedCommentThreads[0]}
        commentEditor={{
          placeholderText: "Leave a comment or @mention",
          saveContentCallback: onSaveComment,
        }}
        loadCommentThreads={loadCommentThreads}
        commentThreadFunctions={{
          forceShowToast: () => null,
          handleHistoryUpdate: () => {
            refreshSelectedTextItemActivity();
          },
          handleInlineReplyClick: () => null,
          handleScrollTo: () => null,
          handleDocUpdateParent: () => null,
          handleCommentChangeClick: () => null,
          updateCommentThread: () => null,
          fetchCompInfo: () => null,
          onAcceptSuggestion: (...args) => {},
          onRejectSuggestion: (...args) => {},
        }}
        possibleMentions={users}
        isCommentingDisabled={false}
        onSeeAllActivity={() => setDetailsPanelEditState("ACTIVITY")}
      />
    </div>
  );
}

export default Comments;
