import { atom, Getter } from "jotai";
import { unwrap } from "jotai/utils";
import isEqual from "lodash.isequal";

interface IAsyncMutableDerivedAtomProps<T> {
  /**
   * The function to load the initial data for the atom. Also used to refresh the data.
   * @param get The jotai getter method. USe this to create a dependency on any atom that should trigger a refresh.
   * @returns The initial data for the atom.
   */
  loadData: (get: Getter) => Promise<T>;
  /**
   * The debug label for the atom.
   */
  debugLabel?: string;
}

/**
 * Creates an async mutable derived atom. What that means is that this atom will initially load data asynchronously and then allow you to override that data with a new value. In practice, you'll want to use this atom type for any one off data that you want to load asynchronously and then allow the user to modify (e.g. the current project).
 * @returns An object with two properties:
 * - valueAtom: The atom that holds the current value of the data. This atom is read/write.
 * - refreshAtom: The atom that triggers a refresh of the data.
 */
export default function asyncMutableDerivedAtom<T>(props: IAsyncMutableDerivedAtomProps<T>) {
  const debugPrefix = props.debugLabel ? `${props.debugLabel}` : "AsyncMutableDerivedAtom";
  const _versionAtom = atom(0);

  const _initialValueAtom = atom((get) => {
    get(_versionAtom);
    return { id: crypto.randomUUID().toString(), data: props.loadData(get) };
  });

  const _overrideAtom = atom(
    undefined as { override: T; overriddenVersion: number; initialAtomId: string } | undefined
  );

  const valueAtom = atom(
    async (get) => {
      const initialValue = get(_initialValueAtom);
      const version = get(_versionAtom);
      const override = get(_overrideAtom);
      if (override && override.overriddenVersion === version && initialValue.id === override.initialAtomId) {
        return override.override;
      }
      return initialValue.data;
    },
    async (get, set, newValue: T | Promise<T>) => {
      set(_overrideAtom, {
        override: await newValue,
        overriddenVersion: get(_versionAtom),
        initialAtomId: get(_initialValueAtom).id,
      });
    }
  );

  const refreshAtom = atom(null, (_get, set) => {
    set(_versionAtom, (v) => v + 1);
  });

  const _hasChanged = atom(async (get) => {
    const initialValue = await get(_initialValueAtom).data;
    const currentValue = await get(valueAtom);
    return !isEqual(initialValue, currentValue);
  });

  const hasChanged = unwrap(_hasChanged, (prev) => prev ?? false);

  const resetAtom = atom(null, (_get, set) => {
    set(_overrideAtom, undefined);
  });

  // MARK: - Debug Labels
  _versionAtom.debugLabel = `${debugPrefix} Internal Version`;
  _initialValueAtom.debugLabel = `${debugPrefix} Internal Initial Value`;
  _overrideAtom.debugLabel = `${debugPrefix} Internal Override`;
  _hasChanged.debugLabel = `${debugPrefix} Internal Has Changed`;

  valueAtom.debugLabel = `${debugPrefix} Value`;
  refreshAtom.debugLabel = `${debugPrefix} Refresh`;
  hasChanged.debugLabel = `${debugPrefix} Has Changed`;

  return { valueAtom, refreshAtom, resetAtom, hasChanged };
}
