import classNames from "classnames";
import React from "react";
import Label from "../../atoms/Label";
import Toggle from "../../atoms/Toggle";
import style from "./index.module.css";

export interface IVariantTab {
  id: string;
  name: string;
}

interface IVariantTabsProps {
  variantTabs: IVariantTab[];
  activeVariant: IVariantTab;

  /**
   * Handler for when a tab in the group is clicked.
   * @param tab The clicked variant tab.
   * @returns
   */
  onTabClick: (tab: IVariantTab) => void;

  className?: string;
}

export function VariantTabs(props: IVariantTabsProps) {
  const { variantTabs, activeVariant, className, onTabClick } = props;

  return (
    <div className={classNames(style.VariantTabsList, className)}>
      {variantTabs.map((variantTab, idx) => {
        const isActive = variantTab.id === activeVariant.id;
        // show divider when tab is not last, not active, and not preceding active tab
        const showDivider =
          idx !== variantTabs.length - 1 &&
          !isActive &&
          !(idx + 1 <= variantTabs.length - 1 && variantTabs[idx + 1].id === activeVariant.id);
        return (
          <>
            <Toggle
              pressed={variantTab.id === activeVariant.id}
              onPressedChange={() => onTabClick(variantTab)}
              className={style.VariantTabsTrigger}
            >
              <Label className={style.VariantTabsLabel}>{variantTab.name}</Label>
            </Toggle>
            {showDivider && <div className={style.VariantTabsDivider} />}
          </>
        );
      })}
    </div>
  );
}

export default VariantTabs;
