import style from "./style.module.css";

/**
 * A component that displays text with a highlighted word if it is present in the text
 *
 * @param props text: string; highlightedWord?: string - contains the text to be displayed and the optional phrase to be highlighted
 * @returns a div containing un-styled text with the highlighted word wrapped in a span which applies the highlighted background
 */
function SearchHighlightedText(props: { text: string; highlightedPhrase?: string }) {
  if (!props.highlightedPhrase?.trim()) {
    return <>{props.text}</>;
  }

  // Find the index of all matches in the text
  const indexes = [...props.text.matchAll(new RegExp(props.highlightedPhrase, "gi"))].map((a) => a.index);

  let cursor = 0;
  const highlightedText: React.ReactNode[] = [];

  while (cursor < props.text.length) {
    if (indexes.includes(cursor)) {
      highlightedText.push(
        <span key={cursor} className={style.highlighted}>
          {props.text.substring(cursor, cursor + props.highlightedPhrase.length)}
        </span>
      );
      cursor += props.highlightedPhrase.length;
      continue;
    }

    highlightedText.push(<span key={cursor}>{props.text[cursor]}</span>);
    // Move on to next character
    cursor += 1;
  }

  return <div>{highlightedText}</div>;
}

export default SearchHighlightedText;
