import { textItemFamilyAtom } from "@/stores/Project";
import {
  onTextItemCancelEditActionAtom,
  onTextItemClickActionAtom,
  onTextItemTextChangeActionAtom,
  selectedTextItemIdsAtom,
} from "@/stores/ProjectSelection";
import { hiddenFieldsAtom } from "@/stores/TextItemMetadata";
import { usersByIdAtom } from "@/stores/Workspace";
import DragAndDroppable from "@ds/atoms/DragAndDroppable";
import TextItem, { TextItemComponentState, TextItemEditState } from "@ds/molecules/TextItem";
import { IVariantTab } from "@ds/molecules/VariantTabs";
import { RESET } from "@shared/frontend/stores/symbols";
import { BASE_VARIANT_ID } from "@shared/types/FigmaVariables";
import { ITipTapRichText } from "@shared/types/TextItem";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { inlineEditingAtom, reorderTextItemsActionAtom, updateTextActionAtom } from "@/stores/Editing";
import { ActualComponentStatus } from "@shared/types/TextItem";
import classNames from "classnames";
import { useMemo } from "react";
import { z } from "zod";
import style from "./style.module.css";

interface ITextItemWrapperProps {
  textItemId: string;
  activeVariant: IVariantTab;
}

function TextItemWrapper(props: ITextItemWrapperProps) {
  const textItem = useAtomValue(textItemFamilyAtom(props.textItemId));
  const usersById = useAtomValue(usersByIdAtom);
  const selectedTextItemIds = useAtomValue(selectedTextItemIdsAtom);

  // hidden metadata fields
  const hiddenFields = useAtomValue(hiddenFieldsAtom);
  const isStatusHidden = hiddenFields?.status.isHidden;
  const isAssignedHidden = hiddenFields?.assigned.isHidden;
  const isTagsHidden = hiddenFields?.tags.isHidden;
  const isNotesHidden = hiddenFields?.notes.isHidden;
  const isVariantsHidden = hiddenFields?.variants.isHidden;
  const isCommentsHidden = hiddenFields?.comments.isHidden;
  const isInstancesHidden = hiddenFields?.instances.isHidden;

  const onTextItemClick = useSetAtom(onTextItemClickActionAtom);
  const onTextItemTextChange = useSetAtom(onTextItemTextChangeActionAtom);
  const onTextItemCancelEdit = useSetAtom(onTextItemCancelEditActionAtom);
  const reorderTextItemsAction = useSetAtom(reorderTextItemsActionAtom);
  const updateText = useSetAtom(updateTextActionAtom);

  const [inlineEditingState, setInlineEditingState] = useAtom(inlineEditingAtom);

  const draggableItems = useMemo(
    () =>
      Array.from(new Set([...selectedTextItemIds, props.textItemId])).map((item) => ({
        "ditto/textItem": item,
        "plain/text": item,
      })),
    [selectedTextItemIds, props.textItemId]
  );

  // variant metadata
  let richText = textItem.rich_text;
  let status = textItem.status;
  let isEmptyVariant = false;
  const variantStatuses: ActualComponentStatus[] = [];

  if (props.activeVariant.id !== BASE_VARIANT_ID) {
    const textItemVariant = textItem.variants.find((variant) => variant.variantId === props.activeVariant.id);

    if (textItemVariant) {
      richText = textItemVariant.rich_text;
      status = textItemVariant.status ?? "NONE";
      isEmptyVariant = false;
    } else {
      isEmptyVariant = true;
    }
  }

  textItem.variants.forEach((variant) => variantStatuses.push(variant.status ?? "NONE"));

  // selection
  const isSelected = selectedTextItemIds.includes(textItem._id);
  const isEditingInline = inlineEditingState?.type === "existing" && inlineEditingState.id === textItem._id;

  // comments badge
  const numComments = textItem.comment_threads.reduce((acc, thread) => acc + thread.comments.length, 0);

  function getDisplayState(): TextItemComponentState {
    if (isSelected) return "focus";
    return "default";
  }

  function getEditState(): TextItemEditState {
    if (isEditingInline) return "editing";
    if (isSelected) return "editable";
    return "none";
  }

  function handleSaveInlineEdit(richText: ITipTapRichText) {
    // TODO: support inline edits for variants
    // https://linear.app/dittowords/issue/DIT-8085/support-inline-editing
    updateText(textItem._id, richText);
    setInlineEditingState(RESET);
  }

  // HANDLE DRAG AND DROP

  function handleDrop(textItemIds: string[], dragLocation: "above" | "below" | null) {
    reorderTextItemsAction([
      {
        textItemIds: textItemIds,
        blockId: textItem.blockId,
        before: dragLocation === "above" ? props.textItemId : undefined,
        after: dragLocation === "below" ? props.textItemId : undefined,
      },
    ]);
  }

  return (
    <DragAndDroppable
      className={style.dragWrapper}
      draggableItems={draggableItems}
      allowedItemKeys={{ "ditto/textItem": z.string() }}
      onDrop={handleDrop}
    >
      {(dragAndDropProps) => {
        return (
          <>
            {dragAndDropProps.isDropTarget && dragAndDropProps.dragLocation === "above" && (
              <div className={classNames(style.dropIndicatorWrapper, style.above)}>
                <div className={style.dropIndicator} />
              </div>
            )}
            <div
              className={classNames(style.draggableContent, {
                [style.droppingAbove]: dragAndDropProps.isDropTarget && dragAndDropProps.dragLocation === "above",
                [style.droppingBelow]: dragAndDropProps.isDropTarget && dragAndDropProps.dragLocation === "below",
              })}
              onClick={(e) => onTextItemClick({ id: props.textItemId, richText: textItem.rich_text, e })}
            >
              <TextItem
                defaultValue={richText}
                // TODO: add support for highlighting again with rich text.
                // text={textItem.text}
                // displayText={<SearchHighlightedText text={textItem.text} highlightedPhrase={projectContentSearchQuery} />}
                status={!isStatusHidden ? status : undefined}
                variantStatuses={!isVariantsHidden ? variantStatuses : undefined}
                numComments={!isCommentsHidden ? numComments : undefined}
                assignee={!isAssignedHidden ? usersById[textItem.assignee ?? ""] : undefined}
                instanceCount={!isInstancesHidden ? textItem.integrations?.figmaV2?.instances?.length ?? 0 : undefined}
                tags={!isTagsHidden ? textItem.tags : undefined}
                notes={!isNotesHidden ? textItem.notes : undefined}
                state={getDisplayState()}
                editState={getEditState()}
                onClickCancel={() => onTextItemCancelEdit(props.textItemId)}
                onClickSave={handleSaveInlineEdit}
                level={isEmptyVariant ? "empty-variant" : "default"}
                onTextChange={(richText) => onTextItemTextChange({ id: props.textItemId, richText })}
              />
            </div>
            {dragAndDropProps.isDropTarget && dragAndDropProps.dragLocation === "below" && (
              <div className={classNames(style.dropIndicatorWrapper, style.below)}>
                <div className={style.dropIndicator} />
              </div>
            )}
          </>
        );
      }}
    </DragAndDroppable>
  );
}

export default TextItemWrapper;
