import { deleteTextItemActionAtom } from "@/stores/Editing";
import { modalAtom } from "@/stores/Modals";
import { projectIdAtom, textItemFamilyAtom } from "@/stores/Project";
import { selectedTextItemIdsAtom, setSelectedTextIdsActionAtom } from "@/stores/ProjectSelection";
import Comments from "@/views/NS/ProjectNS/components/DetailsPanel/Comments";
import DeleteTextItem from "@/views/NS/ProjectNS/components/DetailsPanel/DeleteTextItem";
import MetadataPanel from "@/views/NS/ProjectNS/components/DetailsPanel/Metadata";
import InstanceCount from "@ds/molecules/InstanceCount";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";

interface TextItemDetailsProps {
  className?: string;
}

const TextItemDetails = (props: TextItemDetailsProps) => {
  const [selectedTextItemIds] = useAtom(selectedTextItemIdsAtom);
  const setSelectedTextItemIds = useSetAtom(setSelectedTextIdsActionAtom);
  const [textItem, setTextItem] = useAtom(textItemFamilyAtom(selectedTextItemIds[0]));

  const projectId = useAtomValue(projectIdAtom);
  const deleteTextItemAction = useSetAtom(deleteTextItemActionAtom);

  const setModal = useSetAtom(modalAtom);

  function showDeleteTextItemModal() {
    setModal({
      headline: "Delete text item?",
      content: `The text item and all of its data will be deleted, and linked text layers
        will be unlinked. This can't be undone.`,
      onAction: deleteTextItem,
      onOpenChange: (open) => {
        if (open) return;
        // cleanup logic for cancel
        setModal(null);
      },
      type: "danger",
    });
  }

  const deleteTextItem = async () => {
    if (projectId === null) {
      setModal(null);
      return;
    }

    deleteTextItemAction(selectedTextItemIds[0]);

    // clear selection state
    setSelectedTextItemIds([]);
    setModal(null);
  };

  return (
    <>
      <InstanceCount count={textItem.integrations?.figmaV2?.instances?.length ?? 0} onPreviewClick={() => {}} />
      <MetadataPanel />
      <Comments />
      {selectedTextItemIds.length === 1 && <DeleteTextItem onClick={showDeleteTextItemModal} />}
    </>
  );
};

export default TextItemDetails;
