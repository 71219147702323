import { z } from "zod";
import { BackendSchema, FrontendSchema, ZObjectId } from "./lib";

export const ZVariant = z.object({
  _id: ZObjectId,
  workspace_ID: ZObjectId,
  name: z.string(),
  description: z.string(),
  apiID: z.string(),
  docs: z.array(
    z.object({
      doc_ID: ZObjectId,
      frames: z.array(ZObjectId),
    })
  ),
  components: z.array(ZObjectId),
  folder_id: ZObjectId.nullable(),
  isSample: z.boolean(),
});

export type IVariant = z.infer<typeof ZVariant>;
export type IFVariant = FrontendSchema<IVariant>;
export type IBVariant = BackendSchema<IVariant>;

const ZVariantFolderGroup = z.object({
  folderId: ZObjectId.nullable(),
  folderName: z.string(),
  variants: z.array(
    z.object({
      variantId: ZObjectId,
      variantName: z.string(),
    })
  ),
});

type IVariantFolderGroup = z.infer<typeof ZVariantFolderGroup>;
export type IFVariantFolderGroup = FrontendSchema<IVariantFolderGroup>;
export type IBVariantFolderGroup = BackendSchema<IVariantFolderGroup>;
