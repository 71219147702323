import * as RTooltip from "@radix-ui/react-tooltip";
import classNames from "classnames";
import React from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import style from "./index.module.css";

interface IProps {
  children: React.ReactNode;

  /**
   * The content of the tooltip. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  content: string | React.ReactNode;

  /**
   * The icon to be displayed in the tooltip. Should be an icon as React node, e.g. <Flag />.
   */
  icon?: React.ReactNode;

  /**
   * The header of the tooltip. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  header?: string | React.ReactNode;

  /**
   * The delay in milliseconds before the tooltip appears when hovering over the triggering element.
   * Defaults to 200.
   */
  hoverDelay?: number;
  side?: "top" | "right" | "bottom" | "left";
  size?: "base" | "sm";
  disabled?: boolean;

  /**
   * Style variants.
   */
  type?: "default" | "invert";

  // If you need to pass any of the Radix UI Tooltip props, you can do so here.
  // See https://www.radix-ui.com/primitives/docs/components/tooltip
  rTooltipProps?: RTooltip.TooltipProps;
  rTriggerProps?: RTooltip.TooltipTriggerProps;
  rContentProps?: RTooltip.TooltipContentProps;
}

/**
 * Displays content related to an element when it receives hover or focus.
 * Usage: wrap the triggering element with `<Tooltip>`, and pass in your desired content in the `content`, `header`, and `icon` props.
 */
export function Tooltip(props: IProps) {
  const delay = props.hoverDelay ?? 200;
  const side = props.side ?? "top";

  return (
    <RTooltip.Provider delayDuration={delay}>
      <RTooltip.Root open={props.disabled ? false : undefined} {...props.rTooltipProps}>
        <RTooltip.Trigger disabled={props.disabled} asChild {...props.rTriggerProps}>
          <div className={style.tooltipTrigger}>{props.children}</div>
        </RTooltip.Trigger>
        <RTooltip.Portal>
          <RTooltip.Content side={side} align="center" sideOffset={5} asChild {...props.rContentProps}>
            {props.content && (
              <TooltipContent
                icon={props.icon}
                header={props.header}
                content={props.content}
                arrow={
                  <RTooltip.Arrow
                    className={classNames(style.tooltipArrow, { [style[`type-${props.type ?? "default"}`]]: true })}
                  />
                }
                size={props.size}
                type={props.type}
              />
            )}
          </RTooltip.Content>
        </RTooltip.Portal>
      </RTooltip.Root>
    </RTooltip.Provider>
  );
}

interface IBodyProps {
  /**
   * The icon to be displayed in the tooltip. Should be an icon as React node, e.g. <Flag />.
   */
  icon?: React.ReactNode;

  /**
   * The header of the tooltip. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  header?: string | React.ReactNode;

  /**
   * The content of the tooltip. Can be a string or a React node; note that you'll need to style any React nodes yourself.
   */
  content: string | React.ReactNode;
  arrow?: React.ReactNode;
  size?: "base" | "sm";

  /**
   * Style variants.
   */
  type?: "default" | "invert";
}
export const TooltipContent = React.forwardRef<HTMLDivElement, IBodyProps>((props, ref) => {
  const { icon, header, content, size = "base", type = "default", ...rest } = props;

  // Base size tooltip gets a small icon; small size gets an xs icon.
  const iconSize = size === "sm" ? "xs" : "small";

  return (
    <div
      {...rest}
      ref={ref}
      className={classNames(style.tooltipContent, {
        [style.small]: size === "sm",
        [style[`type-${type}`]]: true,
      })}
    >
      {props.arrow && props.arrow}
      {icon && <Icon Icon={icon} size={iconSize} />}
      <div className={style.textWrapper}>
        {header && <>{typeof header === "string" ? <Text className={style.header}>{header}</Text> : header}</>}
        {content && <>{typeof content === "string" ? <Text className={style.content}>{content}</Text> : content}</>}
      </div>
    </div>
  );
});

export default Tooltip;
