import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/EditOutlined";
import classNames from "classnames";
import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import SearchHighlightedText from "../../molecules/SearchHighlightedText";
import style from "./index.module.css";

interface IInlineEditableNameProps {
  className?: string;
  style?: React.CSSProperties;

  textStyleClass?: string;

  /**
   * Optional string to highlight a portion of the name (e.g. for search results).
   */
  highlightedPhrase?: string;

  /**
   * Initial name value of editable name input.
   */
  name: string;

  /**
   * Handler for saving current edits to the name.
   */
  onSave: (name: string) => void;

  // Callback executed after canceling out of a rename
  onReset?: () => void;

  // Callback executed every time the name input changes
  onChange?: (name: string) => void;

  /**
   * Option to replace the default onBlur functionality, which is to call onSave.
   */
  onBlur?: () => void;

  /**
   * Placeholder text for empty text edit field.
   */
  placeholder?: string;

  /**
   * Styled presets.
   */
  variant?: "default" | "code" | "header";

  /**
   * Size presets.
   */
  size?: "base" | "sm";

  forceHoverState?: boolean;

  /**
   * Styles that will be applied to both the display text and the text in the input.
   */
  textStyles?: React.CSSProperties;

  autofocus?: boolean;
}

export function InlineEditableName(props: IInlineEditableNameProps) {
  const { placeholder, name, onSave, variant = "default", size } = props;
  const [editing, setEditing] = useState(props.autofocus ?? false);
  const [currName, setCurrName] = useState(name);
  const [inputWidth, setInputWidth] = useState(0);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const displayRef = React.useRef<HTMLDivElement>(null);
  const hiddenSpanRef = React.useRef<HTMLSpanElement>(null);

  function startEditing() {
    setCurrName(name);
    setEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }
  function save() {
    setEditing(false);
    onSave(currName);
  }

  function reset() {
    setEditing(false);
    setCurrName(name);

    if (props.onReset) props.onReset();
  }

  function onClick(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    startEditing();
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      save();
    }
    if (e.key === "Escape") {
      reset();
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setCurrName(e.target.value);
    if (props.onChange) props.onChange(e.target.value);
  }

  // Default blur functionality is to save, but this can be customized
  function handleBlurInput(e: React.FocusEvent<HTMLInputElement>) {
    if (props.onBlur) props.onBlur();
    else save();
  }

  // we want the input to be exactly the same width as the display text
  useEffect(function initializeInputWidth() {
    if (displayRef.current) {
      const width = displayRef.current.getBoundingClientRect().width;
      setInputWidth(width);
    }
  }, []);

  useLayoutEffect(
    function recalculateInputWidth() {
      if (hiddenSpanRef.current) {
        const width = hiddenSpanRef.current.getBoundingClientRect().width;
        setInputWidth(width);
      }
    },
    [currName]
  );

  return (
    <div
      style={props.style}
      className={classNames(style.InlineEditableNameWrapper, props.className, {
        [style.editing]: editing,
        [style[`variant-${variant}`]]: true,
        [style.hovering]: props.forceHoverState,
      })}
      onClick={onClick}
      data-testid="inline-editable-name"
      aria-role="button"
    >
      <div className={style.textWrapper}>
        {editing && (
          <>
            <input
              autoFocus={props.autofocus}
              className={classNames(style.input, props.textStyleClass)}
              placeholder={placeholder}
              value={currName}
              onBlur={handleBlurInput}
              onChange={handleInputChange}
              onKeyDownCapture={handleKeyDown}
              ref={inputRef}
              style={{ width: `${inputWidth}px`, ...props.textStyles }}
            />

            {/* Invisible hidden span used to calculate dynamic input width */}
            <span
              ref={hiddenSpanRef}
              className={classNames(style.InlineEditableNameLabel, props.textStyleClass, style.hidden)}
              style={{ ...props.textStyles }}
            >
              {currName || props.placeholder}
            </span>
          </>
        )}
        {!editing && (
          <Text
            onClick={onClick}
            className={classNames(style.InlineEditableNameLabel, props.textStyleClass)}
            ref={displayRef}
            style={{ ...props.textStyles }}
          >
            {props.name === "" && props.placeholder}
            {props.name !== "" && <SearchHighlightedText text={name} highlightedPhrase={props.highlightedPhrase} />}
          </Text>
        )}
      </div>

      {editing ? (
        <>
          <Button variant="icon" size="xs" iconSize="xs" onClick={save}>
            <CheckIcon className={style.checkIcon} />
          </Button>
          <Button variant="icon" size="xs" iconSize="xs" onClick={reset}>
            <CloseIcon className={style.closeIcon} />
          </Button>
        </>
      ) : (
        <Button variant="icon" size="xs" iconSize="xs" onClick={startEditing}>
          <EditIcon className={style.editIcon} />
        </Button>
      )}
    </div>
  );
}

export default InlineEditableName;
