import TextItem from "@ds/molecules/TextItem";
import { IVariantTab } from "@ds/molecules/VariantTabs";
import { Suspense } from "react";
import TextItemWrapper from "../TextItemWrapper";

interface ITextItemRowProps {
  textItemId: string;
}

function TextItemRow(props: { textItemId: string; activeVariant: IVariantTab }) {
  const { textItemId, activeVariant } = props;

  return (
    <Suspense
      key={textItemId}
      fallback={
        <TextItem
          key={textItemId}
          defaultValue={{
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "Loading...",
                  },
                ],
              },
            ],
          }}
        />
      }
    >
      <TextItemWrapper textItemId={textItemId} activeVariant={activeVariant} />
    </Suspense>
  );
}

export default TextItemRow;
