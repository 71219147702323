import Button from "@ds/atoms/Button";
import React from "react";
import style from "./style.module.css";

interface IDeleteTextItemProps {
  onClick: () => void;
}

function DeleteTextItem(props: IDeleteTextItemProps) {
  return (
    <div className={style.DeleteTextItemWrapper}>
      <Button className={style.DeleteTextItemButton} size="xs" variant="text" level="subtle" onClick={props.onClick}>
        Delete text item
      </Button>
    </div>
  );
}

export default DeleteTextItem;
