import { editableBlockHasChangesAtom, unwrappedEditableBlockAtom, updateEditableBlockAtom } from "@/stores/Editing";
import { deleteBlocksActionAtom, renameBlockActionAtom } from "@/stores/Project";
import Button from "@ds/atoms/Button";
import Text from "@ds/atoms/Text";
import TextInput from "@ds/atoms/TextInput";
import DialogueModal from "@ds/molecules/DialogueModal";
import { RESET } from "@shared/frontend/stores/symbols";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useState } from "react";
import style from "./style.module.css";

interface BlockDetailsProps {
  selectedBlockId: string;
}

const BlockDetails = (props: BlockDetailsProps) => {
  const editableBlock = useAtomValue(unwrappedEditableBlockAtom);
  const updateEditableBlock = useSetAtom(updateEditableBlockAtom);
  const hasChanges = useAtomValue(editableBlockHasChangesAtom);
  const renameBlockAction = useSetAtom(renameBlockActionAtom);
  const [showDeleteBlockConfirmation, setShowDeleteBlockConfirmation] = useState(false);
  const deleteBlocksAction = useSetAtom(deleteBlocksActionAtom);

  function handleDeleteBlock() {
    deleteBlocksAction([props.selectedBlockId]);
  }

  function cancelChanges() {
    updateEditableBlock(RESET);
  }

  function saveChanges() {
    if (editableBlock) renameBlockAction(props.selectedBlockId, editableBlock.name);
  }

  function onChangeName(name: string) {
    updateEditableBlock((p) => {
      if (!p) return p;
      else
        return {
          ...p,
          name,
        };
    });
  }

  if (!editableBlock) return <React.Fragment />;

  return (
    <>
      <div className={style.blockDetailsWrapper}>
        <div className={style.blockDetails}>
          <Text weight="strong" className={style.label}>
            Block name
          </Text>
          <TextInput placeholder="Edit block name..." value={editableBlock.name} onChange={onChangeName} />

          {hasChanges && (
            <div className={style.ctas}>
              <Button onClick={cancelChanges} variant="text" level="primary">
                Cancel
              </Button>
              <Button onClick={saveChanges}>Save</Button>
            </div>
          )}
        </div>
      </div>

      <div className={style.deleteBlockArea}>
        <Button size="xs" variant="text" level="subtle" onClick={() => setShowDeleteBlockConfirmation(true)}>
          Delete block
        </Button>
      </div>

      <DialogueModal
        open={showDeleteBlockConfirmation}
        onOpenChange={setShowDeleteBlockConfirmation}
        onAction={handleDeleteBlock}
        headline="Delete block?"
        content="The text items in this block will move to the bottom of the page, outside of any blocks."
        actionText="Delete"
        type="danger"
      />
    </>
  );
};

export default BlockDetails;
