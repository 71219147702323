import style from "./style.module.css";

function CommentsPanel() {
  return (
    <div className={style.wrapper}>
      {" "}
      <p>Comments Panel</p>
    </div>
  );
}

export default CommentsPanel;
