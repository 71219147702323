import classNames from "classnames";
import React from "react";
import Icon from "../../atoms/Icon";
import Text from "../../atoms/Text";
import { useNativeProps } from "../../useNativeProps";
import style from "./index.module.css";

interface SelectOptionProps {
  children: React.ReactNode;

  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  helpText?: string;
  disabled?: boolean;

  className?: string;
}

export const SelectOptionWrapper = React.forwardRef<HTMLDivElement, SelectOptionProps>(function SelectItem(props, ref) {
  const { children, leadingIcon, trailingIcon, helpText, disabled, className, ...rest } = props;

  const nativeProps = useNativeProps<HTMLDivElement, typeof rest>(props, {});

  return (
    <div hidden={props.disabled} {...nativeProps} ref={ref} className={classNames(style.selectItem, props.className)}>
      {props.leadingIcon && <Icon Icon={props.leadingIcon} size="xs" className={style.selectItemIcon} />}

      <div className={style.textWrapper}>
        {/* In real usage, this should be the <RadixSelect.ItemText> component. */}
        {props.children}

        {props.helpText && (
          <Text size="micro" className={style.selectItemHelpText}>
            {props.helpText}
          </Text>
        )}
      </div>

      {props.trailingIcon && <Icon Icon={props.trailingIcon} size="xs" className={style.selectItemIcon} />}
    </div>
  );
});

export default SelectOptionWrapper;
