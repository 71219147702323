import { modalAtom } from "@/stores/Modals";
import { projectIdAtom } from "@/stores/Project";
import { wsLastMessageEffectAtom } from "@/stores/websocketAtom";
import DialogueModal from "@ds/molecules/DialogueModal";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import FilterBar from "./components/FilterBar";
import NavBar from "./components/NavBar";
import ProjectContent from "./components/ProjectContent";
import ToastWrapper from "./components/ToastWrapper";
import { WebsocketsHandler } from "./components/WebsocketsHandler";
import style from "./index.module.css";

function ProjectPage() {
  useAtom(wsLastMessageEffectAtom);
  const params = useParams<{ id: string }>();
  const [projectId, setProjectId] = useAtom(projectIdAtom);
  const modal = useAtomValue(modalAtom);

  useEffect(
    function initProjectIdAtom() {
      const projectId = params.id;
      if (!projectId) {
        throw new Error("Project ID in URL is required");
      }
      setProjectId(projectId);
    },
    [params.id, setProjectId]
  );

  return (
    <div className={style.wrapper}>
      {projectId && (
        <>
          <WebsocketsHandler projectId={projectId} />
          <NavBar />
          <FilterBar />
          <ProjectContent />
          {modal && (
            <DialogueModal
              open={!!modal}
              onOpenChange={modal.onOpenChange}
              onAction={modal.onAction}
              headline={modal.headline}
              content={modal.content}
              actionText={modal.actionText}
              type={modal.type}
            />
          )}
          <ToastWrapper />
        </>
      )}
    </div>
  );
}

export default ProjectPage;
