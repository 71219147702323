import { Atom, atom } from "jotai";
import isEqual from "lodash.isequal";

interface IPaginatedAtomProps<AtomType, DependencyType> {
  /**
   * The dependencies atom serves two purposes:
   * 1. It is used to house any dependencies on other atoms that the pageRequest function may need.
   * 2. It is used to trigger a refresh of the data when the dependencies change.
   */
  dependencyAtom: Atom<DependencyType>;
  /**
   * Fetches the next page of data.
   * @returns {Promise<AtomType[]>} The fetched items.
   */
  pageRequest: (page: { page: number; pageSize: number }, dependencies: DependencyType) => Promise<AtomType[]>;
  /**
   * Size of each page. Defaults to 20.
   */
  pageSize?: number;
  /**
   * The debug prefix for the atoms created by this custom atom.
   */
  debugPrefix?: string;
}

/**
 * Custom atom that fetches paginated data asynchonously from some async source (e.g. an API).
 *
 * This custom atom wrapper returns four utility atoms:
 * - valueAtom: Holds the array of currently fetched paginated data. This atom is read/write.
 * - fetchNextPageActionAtom: Action atom that fetches the next page of data.
 * - hasMoreAtom: Atom that indicates if there are more pages to fetch.
 * - loadingAtom: Atom that indicates if the data is currently being fetched.
 *
 * @example
 * ```tsx
 * function Component() {
 *  const value = useAtomValue(valueAtom);
 *  const hasMore = useAtomValue(hasMoreAtom);
 *  const loading = useAtomValue(loadingAtom);
 *  const fetchNextPageAction = useSetAtom(fetchNextPageActionAtom);
 *
 *  function loadMore() {
 *    fetchNextPageAction();
 *  }
 *
 *  return (
 *    <>
 *      {value.map((item, index) => (
 *        <Item key={index} item={item} />
 *      ))}
 *      {hasMore && !loading && <Button onClick={loadMore}>Load More</Button>}
 *    </>
 *  );
 * }
 * ```
 */
export default function paginatedAtom<AtomType, DependencyType>(props: IPaginatedAtomProps<AtomType, DependencyType>) {
  // MARK: - Constants

  // Resolved page size
  const pageSize = props.pageSize ?? 20;
  // Default page object
  const defaultPage = { page: 0, pageSize };

  // MARK: - Internal Atoms

  /**
   * Atom that holds the dependencies for the pageRequest function.
   * This atom is used to trigger a refresh of the data when the dependencies change.
   */
  const _dependencyAtom = atom((get) => get(props.dependencyAtom));

  /**
   * Atom that holds the initial value of the paginated data based on the dependencies atom.
   * Initial value is fetched when the dependencies change.
   */
  const _initialValueAtom = atom((get) => {
    const dependencies = get(_dependencyAtom);
    return props.pageRequest(defaultPage, dependencies);
  });

  /**
   * Atom that holds the current page of data if it has been overridden
   * either by the user, or by fetching a page of data.
   */
  const _overrideAtom = atom<{ value: AtomType[]; dependencies: DependencyType } | undefined>(undefined);

  /**
   * Atom that holds the current page information.
   */
  const _pageAtom = atom({ ...defaultPage });
  /**
   * Atom that indicates if there are more pages to fetch.
   */
  const _hasMoreAtom = atom(true);
  /**
   * Atom that indicates if a page of data is currently being fetched.
   */
  const _loadingAtom = atom(false);

  // MARK: - Public Atoms

  /**
   * Derived atom that holds the paginated data. defaults to the initial value.
   * This atom is read/write. If a new value is set, it will set the override atom.
   */
  const valueAtom = atom(
    (get) => {
      const dependencies = get(_dependencyAtom);
      const override = get(_overrideAtom);
      if (override && isEqual(override.dependencies, dependencies)) {
        return override.value;
      }
      return get(_initialValueAtom);
    },
    async (get, set, newValue: AtomType[] | ((newValue: AtomType[]) => AtomType[])) => {
      if (newValue instanceof Function) {
        set(_overrideAtom, { dependencies: get(_dependencyAtom), value: newValue(await get(valueAtom)) });
      } else {
        set(_overrideAtom, { dependencies: get(_dependencyAtom), value: newValue });
      }
    }
  );

  /**
   * Action atom that fetches the next page of data.
   */
  const fetchNextPageActionAtom = atom(null, async (get, set) => {
    set(_loadingAtom, true);

    // Update page
    const dependencies = get(_dependencyAtom);
    const override = get(_overrideAtom);
    let newPage = get(_pageAtom);
    // Reset page if dependencies change
    if (!isEqual(dependencies, override?.dependencies)) {
      newPage = { ...defaultPage };
    }
    newPage.page += 1;
    set(_pageAtom, newPage);

    // Fetch new page of data
    const newData = await props.pageRequest(newPage, dependencies);
    const initialData = await get(_initialValueAtom);
    const previousData = get(_overrideAtom);
    set(_overrideAtom, { dependencies, value: [...initialData, ...(previousData?.value || []), ...newData] });
    if (newData.length < pageSize) {
      set(_hasMoreAtom, false);
    }
    set(_loadingAtom, false);
  });

  /**
   * Read-only derived atom that indicates if there are more pages to fetch.
   */
  const hasMoreAtom = atom((get) => get(_hasMoreAtom));
  /**
   * Read-only derived atom that indicates if a page of data is currently being fetched.
   */
  const loadingAtom = atom((get) => get(_loadingAtom));

  // MARK: - Debug Labels

  // Add debug label to internal atoms
  _dependencyAtom.debugLabel = `${props.debugPrefix ?? "Paginated Atom"} Internal Dependency Atom`;
  _overrideAtom.debugLabel = `${props.debugPrefix ?? "Paginated Atom"} Internal Override`;
  _pageAtom.debugLabel = `${props.debugPrefix ?? "Paginated Atom"} Internal Page`;
  _hasMoreAtom.debugLabel = `${props.debugPrefix ?? "Paginated Atom"} Internal Has More`;
  _loadingAtom.debugLabel = `${props.debugPrefix ?? "Paginated Atom"} Internal Loading`;
  // Add debug labels to public atoms
  valueAtom.debugLabel = `${props.debugPrefix ?? "Paginated Atom"} Paginated Value`;
  fetchNextPageActionAtom.debugLabel = `${props.debugPrefix ?? "Paginated Atom"} Fetch Next Page Action`;
  hasMoreAtom.debugLabel = `${props.debugPrefix ?? "Paginated Atom"} Has More`;
  loadingAtom.debugLabel = `${props.debugPrefix ?? "Paginated Atom"} Loading`;

  return {
    valueAtom,
    fetchNextPageActionAtom,
    hasMoreAtom,
    loadingAtom,
  };
}
