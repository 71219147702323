import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { ActualComponentStatus } from "../../../../shared/types/TextItem";
import { IFUser } from "../../../../shared/types/User";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import TextInput from "../../atoms/TextInput";
import MultiCombobox from "../../molecules/MultiCombobox";
import Select, { SelectOption } from "../../molecules/Select";
import { AvatarIcon, FlagIcon, InfoIcon, StatusIcon, TagIcon } from "./icons";
import style from "./index.module.css";

interface IProps {
  className?: string;
  style?: React.CSSProperties;
  status: ActualComponentStatus | "MIXED";
  setStatus: (value: ActualComponentStatus) => void;
  users: Record<string, IFUser>;
  assignee: { name: string; id: string } | null;
  setAssignee: (value: { name: string; id: string } | null) => void;
  allTags: {
    value: string;
    label: string;
  }[];
  tags: {
    value: string;
    label: string;
  }[];
  setTags: (value: { value: string; label: string }[]) => void;
  notes: string | null;
  setNotes: (value: string) => void;
  characterLimit: number;
  setCharacterLimit: (value: number) => void;
  showCTAButtons?: boolean;
  onCancel: () => void;
  onSave: () => void;
  onInsertVariable: () => void;
  richTextInput: React.ReactNode;
  displayTextField?: boolean;
}

export function TextItemMetaData(props: IProps) {
  const characterLimitInputRef = useRef<HTMLInputElement>(null);

  useEffect(
    function setCharacterLimitInputSize() {
      if (characterLimitInputRef.current) {
        characterLimitInputRef.current.style.width = `${(props.characterLimit + "").length * 7 + 18}px`;
      }
    },
    [props.characterLimit]
  );

  return (
    <div
      style={props.style}
      className={classNames(style.TextItemMetaDataWrapper, props.className)}
      data-testid="textItemMetaData"
    >
      {(props.displayTextField ?? true) && (
        <>
          <div className={classNames(style.flexStart, style.spacingBottomLg)}>
            <Text size="small" weight="strong">
              Text
            </Text>
          </div>
          <div className={style.spacingBottomLg}>{props.richTextInput}</div>
        </>
      )}
      <div className={style.flexApart}>
        <div>
          <FlagIcon />
          <span className={style.textItemMetaDataInputLabel}>Status</span>
        </div>
        <div className={style.textItemMetaDataInputWidth}>
          <StatusSelect status={props.status} setStatus={props.setStatus} />
        </div>
      </div>
      <div className={style.flexApart}>
        <div>
          <AvatarIcon />
          <span className={style.textItemMetaDataInputLabel}>Assign</span>
        </div>
        <div className={style.textItemMetaDataInputWidth}>
          <Select
            value={props.assignee?.id ?? ""}
            onChange={(value) => props.setAssignee(value ? { id: value, name: props.users[value].name } : null)}
          >
            {[
              ...Object.entries(props.users).map(([id, user]) => (
                <SelectOption key={id} label={user.name} value={id} />
              )),
              <SelectOption key={"MIXED"} disabled={true} label="Mixed Assignees" value="MIXED" />,
            ]}
          </Select>
        </div>
      </div>
      <div className={style.flexApart}>
        <div>
          <TagIcon />
          <span className={style.textItemMetaDataInputLabel}>Tags</span>
        </div>
        <div className={style.textItemMetaDataInputWidth}>
          <MultiCombobox
            options={props.allTags.map((tag) => ({ value: tag.value, label: tag.label.toUpperCase() }))}
            selectedItems={props.tags.map((tag) => ({ value: tag.value, label: tag.label.toUpperCase() }))}
            setSelectedItems={(value) => props.setTags(value)}
            onCreateNew={(value) => props.setTags([...props.tags, { value, label: value }])}
          />
        </div>
      </div>
      {props.notes !== null && (
        <div className={style.flexApart}>
          <div>
            <InfoIcon />
            <span className={style.textItemMetaDataInputLabel}>Notes</span>
          </div>
          <div className={style.textItemMetaDataInputWidth}>
            <TextInput size="sm" expand="block" value={props.notes} onChange={(value) => props.setNotes(value)} />
          </div>
        </div>
      )}
      {props.showCTAButtons && (
        <div className={style.ctaButtons}>
          <Button onClick={props.onCancel} className={style.cancelButton}>
            Cancel
          </Button>
          <Button onClick={props.onSave}>Save</Button>
        </div>
      )}
    </div>
  );
}

// Should move to its own organism?
interface IStatusSelectProps {
  status: ActualComponentStatus | "MIXED";
  setStatus: (status: ActualComponentStatus) => void;
}

function StatusSelect(props: IStatusSelectProps) {
  return (
    <Select
      value={props.status}
      onChange={(value) => props.setStatus(value as ActualComponentStatus)}
      leadingIcon={<StatusIcon status={props.status} />}
    >
      <SelectOption leadingIcon={<StatusIcon status="NONE" />} label="None" value="NONE" />
      <SelectOption leadingIcon={<StatusIcon status="WIP" />} label="Work in Progress" value="WIP" />
      <SelectOption leadingIcon={<StatusIcon status="REVIEW" />} label="Ready for Review" value="REVIEW" />
      <SelectOption leadingIcon={<StatusIcon status="FINAL" />} label="Final" value="FINAL" />
      <SelectOption disabled={true} leadingIcon={<StatusIcon status="MIXED" />} label="Mixed" value="MIXED" />
    </Select>
  );
}

export default TextItemMetaData;
