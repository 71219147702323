import classNames from "classnames";
import React from "react";
import { useNativeProps } from "../../useNativeProps";
import Icon from "../Icon";
import Text from "../Text";
import style from "./index.module.css";

interface IProps {
  defaultValue?: string;
  value?: string;
  onChange?: (val: string) => void;

  /**
   * You can pass in an icon like Icon={\<CheckIcon \/>}.
   * You can also pass in a straight-up SVG element like Icon={\<svg>...\</svg>}.
   * The icon will inherit the color of the label, but can be overridden by passing in the iconColor prop.
   */
  className?: string;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  trailingButton?: React.ReactNode;
  iconColor?: string;
  placeholder?: string;
  size?: "base" | "sm" | "xs";
  state?: "default" | "disabled" | "error";
  expand?: "inline" | "block";
  errorMessage?: string;
  autoFocus?: boolean;

  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  /**
   * Optional ref for the input.
   */
  inputRef?: React.RefObject<HTMLInputElement>;

  /**
   * Optional style presets.
   */
  level?: "default" | "code";
}

export function TextInput({
  value,
  onChange,
  size,
  leadingIcon,
  trailingIcon,
  trailingButton,
  iconColor,
  expand,
  state,
  className,
  errorMessage,
  onBlur,
  onKeyDown,
  inputRef,
  level = "default",
  ...props
}: IProps) {
  // normal input gets a small icon; small input gets an xs icon
  const iconSize = size === "sm" ? "xs" : "small";

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value);
  }

  const nativeProps = useNativeProps<HTMLInputElement, typeof props>(props, {
    placeholder: true,
  });

  return (
    <>
      <div
        className={classNames(style.inputWrapper, {
          [style.block]: expand === "block",
          [style[`size-${size}`]]: size,
        })}
      >
        {leadingIcon && (
          <Icon
            Icon={leadingIcon}
            className={classNames(style.icon, style.leading)}
            size={iconSize}
            style={{ color: iconColor || undefined }}
          />
        )}

        <input
          className={classNames(style.TextInputWrapper, className, {
            [style.hasLeadingIcon]: !!leadingIcon,
            [style.hasTrailingIcon]: !!trailingIcon,
            [style.error]: state === "error",
            [style.disabled]: state === "disabled",
            [style[`level-${level}`]]: true,
          })}
          disabled={state === "disabled"}
          data-testid="text-input"
          {...nativeProps}
          value={value}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={handleChange}
          ref={inputRef}
        />

        {trailingIcon && (
          <Icon
            Icon={trailingIcon}
            className={classNames(style.icon, style.trailing)}
            size={iconSize}
            style={{ color: iconColor || undefined }}
          />
        )}
      </div>
      {errorMessage && (
        <Text color="danger" size="small">
          {errorMessage}
        </Text>
      )}
    </>
  );
}

export default TextInput;
